import { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Typography } from "antd";
import axios from "../../../../utils/axios";
import moment from "moment";
import { channelController } from "../../../../controllers/channelController";
import { toast } from "react-hot-toast";
import { getToken } from "../../../../utils/token";

const { Text } = Typography;

const RequestDetails = ({ open, setopen, details, refreshBtn }) => {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [chanelinfo, setchanelinfo] = useState(null);
  const [showAliasInput, setShowAliasInput] = useState(false);

  const handleAccept = async () => {
    try {
      setloading(true);
      const alias = form.getFieldValue("alias");
      const res = await axios.post("/submeter/acceptchanneladdrequest", {
        channelid: details.channelid,
        alias,
      });
      const result = channelController(res);

      if (result.type !== "success") {
        toast[result.type](result.message);
        setloading(false);
        return;
      }

      if (result.message.response === "false") {
        toast.error("Operation failed. Please try again.");
        setloading(false);
        return;
      }

      toast.success("Request accepted");
      setopen(false);
      refreshBtn((prev) => !prev);
    } catch (err) {
      toast.error(err.message);
      setloading(false);
    }
  };

  const handleDecline = async () => {
    try {
      setloading(true);
      const res = await axios.post("/submeter/cancelchanneladdrequest", {
        channelid: details.channelid,
      });
      const result = channelController(res);

      if (result.type !== "success") {
        toast[result.type](result.message);
        setloading(false);
        return;
      }

      if (result.message.response === "false") {
        toast.error("Operation failed. Please try again.");
        setloading(false);
        return;
      }

      toast.success("Request declined");
      setopen(false);
      refreshBtn((prev) => !prev);
    } catch (err) {
      toast.error(err.message);
      setloading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const username = await getToken("spiral_username");
        const res = await axios.post("/submeter/getchannelinfo", {
          username,
          channelid: details.channelid,
        });
        const result = channelController(res);

        if (result.type !== "success") {
          toast[result.type](result.message);
          return;
        }

        console.log(result, "result");
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, [details]);

  // console.log(details, "details");
  return (
    <Modal
      title="Incoming Request Details"
      visible={open}
      onCancel={() => setopen(false)}
      footer={null}
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Date Sent">
          <Input value={moment(details.datesent).format("LL")} disabled />
        </Form.Item>
        <div className="grid grid-cols-2 gap-4">
          <Form.Item label="Sender">
            <Input value={details.sender} disabled />
          </Form.Item>
          <Form.Item label="Status">
            <Input value={details.status} disabled />
          </Form.Item>
        </div>

        <Form.Item label="Channel Name">
          <Input value={details.channelName || "N/A"} disabled />
        </Form.Item>
        <Form.Item label="Note">
          <Input.TextArea
            value={details.note || "No additional notes provided."}
            disabled
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>
        {showAliasInput && (
          <Form.Item
            label="Assign Name to Channel"
            name="alias"
            rules={[
              { required: true, message: "Please enter a channel name!" },
            ]}
          >
            <Input placeholder="Enter channel name" />
          </Form.Item>
        )}
        {!details.status == "canceled" && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {!showAliasInput ? (
              <>
                <Button danger onClick={handleDecline} loading={loading}>
                  Decline
                </Button>
                <Button
                  type="primary"
                  onClick={() => setShowAliasInput(true)}
                  loading={loading}
                >
                  Accept
                </Button>
              </>
            ) : (
              <>
                <Button onClick={() => setShowAliasInput(false)}>Back</Button>
                <Button type="primary" onClick={handleAccept} loading={loading}>
                  Confirm
                </Button>
              </>
            )}
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default RequestDetails;
