import axios from "../../../../utils/axios";
import moment from "moment";
import { useState } from "react";
import { toast } from "react-hot-toast";
import Backdrop from "../../../Backdrop";
import CloseButton from "../../../CloseButton";
import Rule from "../../../Rule/Index";
import { Button } from "../../../Button";

const ViewPaymentPlan = ({ open, setopen, details, refreshBtn }) => {
  let finalDate = "";
  const [loading, setloading] = useState(false);

  const dateformatter = [
    {
      date: "Sunday",
      value: details?.daybinaries[0],
    },
    {
      date: "Monday",
      value: details?.daybinaries[1],
    },
    {
      date: "Tuesday",
      value: details?.daybinaries[2],
    },
    {
      date: "Wednesday",
      value: details?.daybinaries[3],
    },
    {
      date: "Thursday",
      value: details?.daybinaries[4],
    },
    {
      date: "Friday",
      value: details?.daybinaries[5],
    },
    {
      date: "Saturday",
      value: details?.daybinaries[6],
    },
  ]
    .filter((i) => i?.value == "1")
    .map((i) => i.date);

  if (dateformatter.length > 1) {
    const andClause = "and " + dateformatter[dateformatter.length - 1];
    let formatter = dateformatter.slice(0, dateformatter.length - 1);
    formatter.push(andClause);
    finalDate = formatter.join(", ");
  } else {
    finalDate = `${dateformatter[0]}.`;
  }

  const formattime = (item) =>
    `${item.substring(0, 2)}:${item.substring(2, 4)}`;
  const handleDelete = async (item) => {
    try {
      setloading(true);
      let res = await axios.post("/submeter/paymentplan/deletepaymentplan", {
        planalias: item,
      });
      refreshBtn((prev) => !prev);
      toast.success("Plan deleted successfully.");
      setloading(false);
      setopen(false);
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.response);
        setloading(false);
        return;
      }
      toast.error(err.message);
    }
  };
  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[95%] md:w-[550px] bg-white drop-shadow-md rounded-sm p-3">
          <div className="flex items-start  w-full justify-between">
            <div className="">
              <div className="font-Kanit font-normal text-[15px]">
                Payment plan details
              </div>
              <div className=" hidden w-[80%] text-gray-700 font-light">
                Send a request to user to grant him/her access to your s to the
                channel <br />
              </div>
            </div>
            <div className="fw-[200px] flex justify-end items-center">
              <CloseButton closeBtn={setopen} />
            </div>
          </div>
          <div className="flex items-center justify-end my-4">
            <div className="w-[130px]">
              <Button
                text={"Delete Plan"}
                bg={"white"}
                height={"35px"}
                border="1px solid tomato"
                loaderColor={"tomato"}
                onClick={() => handleDelete(details?.planalias)}
                loading={loading}
                color={"tomato"}
              />
            </div>
          </div>
          <Rule />
          <div className="pb-10">
            <div className="flex items-center justify-between mt-2">
              <div className="text-gray-700 text-[14px]">Plan name:</div>
              <div className="font-semibold text-gray-800 text-[15px]">
                {details?.planalias}
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="text-gray-700 text-[14px]">Description:</div>
              <div className="font-semibold text-gray-800 text-[15px]">
                {details?.description}
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="text-gray-700 text-[14px]">Price:</div>
              <div className="font-semibold text-gray-800 text-[15px]">
                ₦{details?.amount.toLocaleString(undefined)}
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="text-gray-700 text-[14px]">Date created:</div>
              <div className="font-semibold text-gray-800 text-[15px]">
                {moment(details?.datecreated).format("lll")}
              </div>
            </div>
            <div className="flex items-start justify-between mt-2">
              <div className="text-gray-700 text-[14px]">Plan summary:</div>
              <div className="font-semibold w-[60%] text-gray-80 text-right text-gray-800 text-[15px]">
                This plan will be avaliable for {details?.duration}{" "}
                {details?.durationlength}
                {+details?.duration > 1 ? "s" : ""} from{" "}
                {formattime(details.starttime)} to {formattime(details.endtime)}{" "}
                on {finalDate}
                {/* {details?.duration} {details?.durationlength} */}
                {/* {+details?.duration > 1 ? "s" : ""} */}
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="text-gray-700 text-[14px]">Units avaliable:</div>
              <div className="font-semibold text-gray-800 text-[15px]">
                {details?.energyorpower}units
              </div>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default ViewPaymentPlan;
