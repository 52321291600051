import Layout from "../../../components/Layout";
import { themeColor } from "../../../constant/color";
import { CiSettings } from "react-icons/ci";
import { Link } from "react-router-dom";
import AddToGroup from "../../../components/Channels/Groups/AddToGroup";
import { useContext, useEffect, useState } from "react";
import AddMeter from "../../../components/Meter/AddMeter";
import { ChannelContext } from "../../../context/channelContext";
import axios from "../../../utils/axios";
import { channelController } from "../../../controllers/channelController";
import { toast } from "react-hot-toast";
import Skimmer from "../../../components/Loader/Skimmer";
import { BsPlusCircle } from "react-icons/bs";
import ChannelGroups from "../../../components/Channels/Drawer/ChannelGroups";
import { getToken } from "../../../utils/token";
import EnergyCharts from "../../../components/Dashboards/Charts/EnergyChart";
import VendHistory from "../../../components/Dashboards/Charts/Transactions/VendHistory";
import DashboardRevenueHistory from "../../../components/Dashboards/Charts/Transactions/Revenue";
import WalletBalance from "../../../components/Dashboards/WalletBalance";
import { IoIosExpand } from "react-icons/io";
import { Button, Select } from "antd";
import moment from "moment";
import { BillingContext } from "../../../context/BillingContext";
import FaultsCard from "../../../components/Dashboards/FaultsCard";
import { errorBlock } from "../../../controllers/errorBlock";
import { currencyOptions } from "../../Wallet/walletData";
import { useDispatch, useSelector } from "react-redux";
import {
  getWalletBalance,
  getWallethistory,
} from "../../../redux/middleware/billing.action";

const UserDashboard = () => {
  const dispatch = useDispatch();
  const { getEnergyConsumption } = useContext(ChannelContext);
  const [metermodal, setmetermodal] = useState(false);
  const [groupmodal, setgroupmodal] = useState(false);
  const [loading, setloading] = useState(false);

  const [messages, setmessages] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [username, setusername] = useState("");
  // rename state
  const [showrename, setshowrename] = useState(false);
  const [selectedchannel, setselectedchannel] = useState({});
  // group states
  const [showgroup, setshowgroup] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        let res = await getToken("spiral_username");
        setusername(res);
      } catch (err) {
        toast.error(err.message);
      }
    })();
    return () => {};
  }, []);

  const [energydata, setEnergydata] = useState([]);
  const [startdate, setstartdate] = useState(
    moment(Date.now()).subtract("1", "day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [enddate, setenddate] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );
  const [refreshenergy, setrefreshenergy] = useState(false);
  // energy consumption
  useEffect(() => {
    (async () => {
      try {
        let res = await getEnergyConsumption("", startdate, enddate);
        setEnergydata(res?.body);
      } catch (err) {
        toast.error(err.message);
      }
    })();
    return () => {};
  }, [refreshenergy]);

  const handleConsumptionfilter = (e) => {
    switch (e) {
      case "today":
        setstartdate(
          moment(Date.now()).startOf("day").format("YYYY-MM-DD HH:mm:ss")
        );

        setenddate(moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"));
        setrefreshenergy((prev) => !prev);
        break;
      case "week":
        setstartdate(
          moment(Date.now()).startOf("week").format("YYYY-MM-DD HH:mm:ss")
        );

        setenddate(moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"));
        setrefreshenergy((prev) => !prev);

        break;
      case "month":
        setstartdate(
          moment(Date.now()).startOf("month").format("YYYY-MM-DD HH:mm:ss")
        );

        setenddate(moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"));
        setrefreshenergy((prev) => !prev);

        break;
      default:
        break;
    }
    console.log(e);
  };

  // received channel request
  useEffect(() => {
    (async () => {
      try {
        setloading(true);
        let res = await axios.post(
          "/submeter/getreceivedchanneladdrequests",
          {}
        );
        const result = channelController(res);
        if (result.type !== "success") {
          toast.error(result.message);
          setloading(false);
          return;
        }
        setmessages(result.message.body);
        setloading(false);
      } catch (err) {
        console.log(err);
        setloading(false);
      }
    })();
  }, []);

  const voltageSample = [100, 270, 10];
  const [fault, setFault] = useState([]);
  const [loadfault, setloadFault] = useState(true);

  // simulator for alerts
  useEffect(() => {
    setTimeout(() => {
      setloadFault(false);
    }, 5000);
    return () => {};
  });

  const [balance, setbalance] = useState(0);
  const [currency, setcurrency] = useState(1);
  const [loadbalance, setloadbalance] = useState(false);
  const wallet = useSelector((state) => state.billing);

  // get wallet balance
  useEffect(() => {
    dispatch(getWalletBalance(currencyOptions[currency].tag));
  }, [currency]);

  const [vendenddate, setvendenddate] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );

  const [vendstartdate, setvendstartdate] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );

  const [venddata, setvenddata] = useState([]);
  const [vendrefresh, setvendrefresh] = useState([]);

  const handleVendFilter = (e) => {
    switch (e) {
      case "today":
        setvendstartdate(
          moment(Date.now()).startOf("day").format("YYYY-MM-DD HH:mm:ss")
        );

        setvendenddate(moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"));
        setvendrefresh((prev) => !prev);
        break;
      case "week":
        setvendstartdate(
          moment(Date.now()).startOf("week").format("YYYY-MM-DD HH:mm:ss")
        );

        setvendenddate(moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"));
        setvendrefresh((prev) => !prev);

        break;
      case "month":
        setvendstartdate(
          moment(Date.now()).startOf("month").format("YYYY-MM-DD HH:mm:ss")
        );

        setvendenddate(moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"));
        setvendrefresh((prev) => !prev);
        break;
      default:
        break;
    }
  };

  // get vending history
  useEffect(() => {
    (async () => {
      try {
        dispatch(getWallethistory(vendstartdate, vendenddate, 100, 0));
      } catch (err) {
        errorBlock(err);
      }
    })();
  }, [vendrefresh]);

  return (
    <>
      {groupmodal && <AddToGroup closeBtn={setgroupmodal} />}
      <AddMeter
        visible={metermodal}
        closeBtn={setmetermodal}
        refreshBtn={setrefresh}
      />
      {/* {showrename && (
          <RenameModal
            open={showrename}
            setopen={setshowrename}
            refreshBtn={setrefresh}
            closeBtn={setrenamemodal}
            selectedchannel={selectedchannel}
            setselectedchannel={setselectedchannel}
          />
        )} */}
      {showgroup && (
        <ChannelGroups
          title={"Device Groups"}
          open={showgroup}
          setopen={setshowgroup}
          refreshBtn={setrefresh}
          selectedchannel={selectedchannel}
          setselectedchannel={setselectedchannel}
        />
      )}
      <div className="pb-[200px]">
        {/* Top */}
        <div className="flex mb-4 items-center justify-between">
          <div className="flex items-center">
            <div className="text-3xl pr-4 hidden md:block">⛅</div>
            <div className="">
              <div className="text-primary font-semibold text-[16px]">
                Welcome {username}
              </div>
              <div className="text-[13px]">
                Overview of all the meters you manage.
              </div>
            </div>
          </div>

          <Button
            size=""
            type="primary"
            htmlType="submit"
            // loading={loading}
            onClick={() => setmetermodal(true)}
            className="!w-fit  mt-4 !bg-primary !outline-none !border-none"
          >
            Add Meter
          </Button>
        </div>

        {/* Consumption on across meters */}
        {/* <hr className="my-4 border-black-600 border-2" /> */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="col-span-2">
            <div className="bg-white p-2 rounded-md shadow-md h-[auto]">
              <div className="flex items-center justify-between">
                <div className="font-bold">Consumption across meters</div>
                <div className="flex items-center">
                  <Select
                    className="mr-4"
                    defaultValue="today"
                    style={{
                      width: 150,
                    }}
                    onChange={(e) => handleConsumptionfilter(e)}
                    options={[
                      {
                        value: "today",
                        label: "Today",
                      },
                      {
                        value: "week",
                        label: "This week",
                      },
                      {
                        value: "month",
                        label: "This month",
                      },
                    ]}
                  />

                  <div
                    title="Advanced Settings"
                    className="cursor-pointer px-2 mx-1 hidden "
                  >
                    <CiSettings size={20} />
                  </div>
                </div>
              </div>
              <div className="h-[450px]">
                <EnergyCharts data={energydata} />
              </div>
            </div>
          </div>
          {/* Wallet balance */}
          <div className="col-span-1 hidden md:flex bg-white shadow-md p-2 rounded-md ">
            <div className="grid grid-col-1 h-[180px] z-10 bg-white ">
              <WalletBalance
                currency={currency}
                setcurrency={setcurrency}
                balance={wallet?.balance}
                setbalance={setbalance}
                loadbalance={loadbalance}
                setloadbalance={setloadbalance}
                currencyOptions={currencyOptions}
              />
            </div>
            <div className="col-span-1 mt-3 grid grid-cols-2 gap-4">
              <div className="border-[1px] border-gray-300 rounded-md h-[280px]"></div>
              <div className="border-[1px] border-gray-300 rounded-md h-[280px]"></div>
            </div>
          </div>
        </div>
        {/* voltage drop */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
          <div className="col-span-2 ">
            <div className="bg-white p-2 rounded-md shadow-md h-[350px]">
              <div className="flex items-center justify-between">
                <div className="">
                  <div className="font-bold">Faults on meters</div>
                  <div className="font-normal hidden md:flex">
                    The devices below have experienced a voltage drop
                  </div>
                </div>
                <div className="flex items-center justify-end">
                  <div className="flex items-center mr-4">
                    <div className="h-[10px] w-[10px] mr-2 rounded-full bg-red-600"></div>
                    <div className="">High voltage</div>
                  </div>
                  <div className="flex items-center">
                    <div className="h-[10px] w-[10px] mr-2 rounded-full bg-orange-300"></div>
                    <div className="">Low voltage</div>
                  </div>
                </div>
              </div>
              <div className="h-[210px] flex gap-4 pt-3 px-2 ">
                {loadfault ? (
                  Array.from(Array(3)).map((_, i) => (
                    <div key={i} className="rounded-md overflow-hidden">
                      <Skimmer width={200} heigth={200} />
                    </div>
                  ))
                ) : fault.length == 0 ? (
                  <div className="flex flex-col w-[100%] h-[280px] items-center  justify-center">
                    <div className="mt-[-100px]">
                      <div className="pr-2 text-center text-4xl">⚡</div>
                      <div className="">No fault detected across meters</div>
                    </div>
                  </div>
                ) : (
                  voltageSample.map((i, index) => (
                    <FaultsCard key={index} data={i} />
                  ))
                )}
              </div>
            </div>
          </div>
          <div className="col-span-1 hidden md:flex p-2 bg-white rounded-md shadow-md h-[350px]">
            <div className="flex items-center justify-between">
              <div className="font-semibold">
                <span className="pr-2">📩</span>Incoming Request
              </div>
              <Link to="/request" className="">
                View all
              </Link>
            </div>
            <div className="mt-2">
              {loading ? (
                <>
                  {Array.from(Array(7)).map((_, i) => (
                    <div className="my-1" key={i}>
                      <Skimmer heigth={40} />
                    </div>
                  ))}
                </>
              ) : messages.length == 0 ? (
                <div className="flex w-full h-[200px] flex-col items-center justify-center">
                  <div className="text-4xl">📩</div>
                  <div className="mt-4">
                    You don't have any message at the moment.
                  </div>
                </div>
              ) : (
                [1, 2, 3].map((_, i) => (
                  <div className="p-2 even:bg-[#f7f6f6] cursor-pointer">
                    <div className=" flex items-center justify-between">
                      <div className="">
                        Sender: <span className="font-semibold">Zannu</span>
                      </div>
                      <div className="">
                        Device name:{" "}
                        <span className="font-semibold">Bank 1</span>{" "}
                      </div>
                    </div>
                    <div className="mt-1">
                      Note: This is the channel you can access...
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>

        {/* Vend history */}
        <div className="grid grid-cols-1 gap-4 mt-4 ">
          {/* revenue */}
          <div className="bg-white hidden p-2 rounded-md shadow-md">
            <div className="flex align-items justify-between">
              <div className="font-bold">Revenue generate across meters</div>
              <div className="">
                <Select
                  defaultValue="today"
                  style={{
                    width: 120,
                  }}
                  // onChange={handleChange}
                  options={[
                    {
                      value: "today",
                      label: "Today",
                    },
                    {
                      value: "week",
                      label: "This week",
                    },
                    {
                      value: "month",
                      label: "This month",
                    },
                  ]}
                />
              </div>
            </div>
            <div className="h-[400px]">
              <DashboardRevenueHistory />
            </div>
          </div>
          {/* Vend history */}
          <div className="bg-white p-2 rounded-md shadow-md">
            <div className="flex items-center justify-between ">
              <div className="font-bold">Vend history</div>
              <div className="">
                <Select
                  defaultValue="month"
                  style={{
                    width: 120,
                  }}
                  onChange={handleVendFilter}
                  options={[
                    {
                      value: "today",
                      label: "Today",
                    },
                    {
                      value: "week",
                      label: "This week",
                    },
                    {
                      value: "month",
                      label: "This month",
                    },
                  ]}
                />
              </div>
            </div>
            <div className="h-[500px]">
              <VendHistory data={wallet?.walletHistory} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDashboard;
