import DataTable from "react-data-table-component";
import moment from "moment";
import { HiUserGroup } from "react-icons/hi";
import EmptyState from "../../../EmptyState";
import { tableCustomStyles } from "../../../../helperFuncs";

const WalletHistoryTable = ({ data = [], setdetails, setmodal }) => {
  // Custom styles for the DataTable
  const customStyles = {
    rows: {
      style: {
        minHeight: "50px",
        "&:nth-of-type(even)": {
          backgroundColor: "#f9fafb",
        },
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        fontWeight: "600",
        color: "#1f2937",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  // Define columns for the DataTable
  const columns = [
    {
      name: "S/N",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "80px",
      center: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      center: true,
    },
    {
      name: "Date Created",
      selector: (row) => row.date,
      sortable: true,
      center: true,
      format: (row) => moment(row.date).format("lll"),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="w-full flex justify-center">
          <button
            className="border border-secondary text-secondary text-xs font-light px-4 py-2 rounded hover:bg-secondary hover:text-white transition-colors"
            onClick={() => {
              setdetails(row);
              setmodal(true);
            }}
          >
            View
          </button>
        </div>
      ),
      center: true,
    },
  ];

  // Custom empty component
  const CustomEmptyData = () => (
    <EmptyState>
      <div className="h-[70vh] w-full">
        <div className="flex items-center justify-center mt-[200px]">
          <HiUserGroup size={30} />
        </div>
        <div className="font-normal text-[15px] text-center">
          You have not performed any wallet transaction
        </div>
      </div>
    </EmptyState>
  );

  return (
    <DataTable
      columns={columns}
      data={data}
      // customStyles={customStyles}
      pagination
      noDataComponent={<CustomEmptyData />}
      responsive
      highlightOnHover
      pointerOnHover
      customStyles={tableCustomStyles}
      striped
      persistTableHead
    />
  );
};

export default WalletHistoryTable;
