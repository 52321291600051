import { Modal, List, Typography } from "antd";

const { Text } = Typography;

const PlanType = ({ open, setopen, setselectedplantype, opendrawer }) => {
  const handleSelectPlan = (planType) => {
    setselectedplantype(planType);
    opendrawer(true);
    setopen(false);
  };

  const plans = [
    {
      name: "Daily Power Limit",
      value: "powerlimit",
      description: "Set a daily limit for power usage to control consumption.",
    },
    {
      name: "Daily Energy Limit",
      value: "energylimit",
      description: "Restrict the total energy usage allowed for each day.",
    },
    {
      name: "Cumulative Power Limit",
      value: "cummulativelimit",
      description:
        "Enforce a cumulative limit for power usage over a specific period.",
    },
  ];

  return (
    <Modal
      title="Add Payment Plan"
      open={open}
      onCancel={() => setopen(false)}
      footer={null}
      centered
    >
      <Text>Select a payment plan type you would like to create:</Text>
      <List
        bordered={false}
        className=""
        dataSource={plans}
        renderItem={(item) => (
          <List.Item
            onClick={() => handleSelectPlan(item.value)}
            className="cursor-pointer hover:bg-gray-100"
            style={{ padding: "10px 16px" }}
          >
            <div>
              <Text strong>{item.name}</Text>
              <br />
              <Text type="secondary">{item.description}</Text>
            </div>
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default PlanType;
