import axios from "../../utils/axios";
import { useEffect, useState, useContext } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { Button } from "../../components/Button";
import Layout from "../../components/Layout";
import PaymentPlanTableMain from "../../components/PaymentPlan/PaymentPlanTableMain";
import Rule from "../../components/Rule/Index";
import { channelController } from "../../controllers/channelController";
import { billingController } from "../../controllers/BillingController";
import { errorBlock } from "../../controllers/errorBlock";
import TableSkimmer from "../../components/TableSkimmer";
import ViewPaymentPlan from "../../components/PaymentPlan/Modals/ViewPaymentPlan";
import AddPlan from "../../components/PaymentPlan/Drawer/AddPlan";
import PlanType from "../../components/PaymentPlan/Modals/PlanType";
import MeterClassTable from "../../components/MeterClass/Table/MeterClassTable";
import ViewMeterClass from "../../components/MeterClass/Modals/ViewMeterClass";
import AddMeterClass from "../../components/MeterClass/Drawers/AddMeterClass";
import ProcessingCard from "../../components/Loader/ProcessingCard";
import MeterClassCard from "../../components/MeterClass/Card/MeterClassCard";
import Skimmer from "../../components/Loader/Skimmer";
const MeterClass = () => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(true);
  const [modal, setmodal] = useState(false);
  const [details, setdetails] = useState(null);
  const [refresh, setrefresh] = useState(false);
  const [addplanmodal, setaddplanmodal] = useState(false);
  const [selectedplantype, setselectedplantype] = useState("");
  const [opendrawer, setopendrawer] = useState(false);
  const [createloading, setcreateloading] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/meterclass/getsavedmeterclasses", {});
        setdata(res.data.body);
        setloading(false);
      } catch (err) {
        if (err?.response) {
          toast.error(err.response?.data?.response);
          setloading(false);
          return;
        }
        toast.error(err.message);
        setloading(false);
      }
    })();
    return () => {};
  }, [refresh]);

  return (
    <>
      <AddMeterClass
        open={opendrawer}
        setopen={setopendrawer}
        refreshBtn={setrefresh}
        loading={createloading}
        setLoading={setcreateloading}
      />
      {modal && (
        <ViewMeterClass
          details={details}
          setopen={setmodal}
          open={modal}
          refreshBtn={setrefresh}
        />
      )}
      {addplanmodal && (
        <PlanType
          open={addplanmodal}
          setopen={setaddplanmodal}
          setselectedplantype={setselectedplantype}
          opendrawer={setopendrawer}
        />
      )}

      {createloading && <ProcessingCard open={createloading} />}

      <>
        <div className="mt-10">
          <div className="bg-white drop-shadow-md rounded-lg mb-24 ">
            <div className="flex items-center justify-between">
              <div className=" pt-3 px-3">
                <div className="font-Kanit text-[17px] font-semibold text-gray-800">
                  All meter class
                </div>
                <div className="text-gray-600">
                  All the meter class you have created
                </div>
              </div>
              <div className="w-[150px]" onClick={() => setopendrawer(true)}>
                <Button text={"Add meterclass"} />
              </div>
            </div>

            <Rule />
            <div className="p-3 hidden md:block">
              {loading ? (
                <TableSkimmer entries={10} col={7} />
              ) : (
                <MeterClassTable
                  data={data}
                  setdetails={setdetails}
                  setmodal={setmodal}
                />
              )}
            </div>
            <div className="block md:hidden p-3">
              {loading
                ? [1, 2, 3, 4, 5].map((_, i) => (
                    <div className="rounded-md overflow-hidden" key={i}>
                      <Skimmer heigth={100} />
                    </div>
                  ))
                : data.map((i, index) => (
                    <MeterClassCard
                      data={i}
                      key={index}
                      setdetails={setdetails}
                      setmodal={setmodal}
                    />
                  ))}
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default MeterClass;
