import { useState } from "react";
import { Modal, Form, Input, Button } from "antd";
import { AiOutlineUser } from "react-icons/ai";
import { getToken } from "../../../../utils/token";
import { useParams } from "react-router-dom";
import { channelController } from "../../../../controllers/channelController";
import axios from "../../../../utils/axios";
import { errorBlock } from "../../../../controllers/errorBlock";
import { toast } from "react-hot-toast";

const RenameModal = ({ open, setopen, refreshBtn, selectedchannel }) => {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const { id } = useParams();

  const handleSubmit = async (values) => {
    try {
      setloading(true);
      const username = await getToken("spiral_username");

      if (selectedchannel?.type === "single") {
        const res = await axios.post("/submeter/editchannelalias", {
          username,
          newalias: values.name,
          channelid: selectedchannel?.channelid,
        });

        const result = channelController(res);
        if (result?.type !== "success") {
          toast[result.type](result?.message);
          setloading(false);
          return;
        }
        refreshBtn((prev) => !prev);
        toast.success("Meter name updated successfully");
      } else {
        const res = await axios.post("/submeter/editchannelgroup", {
          username,
          newgroup: values.name,
          channelid: selectedchannel?.channelid,
        });

        const result = channelController(res);
        if (result?.type !== "success") {
          toast[result.type](result?.message);
          setloading(false);
          return;
        }
        refreshBtn((prev) => !prev);
        toast.success("Meter name updated successfully");
      }
      form.resetFields();
      setopen(false);
    } catch (err) {
      errorBlock(err);
    } finally {
      setloading(false);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={() => setopen(false)}
      title="Update Meter Name"
      centered
      footer={null} // Removes the default footer
    >
      <p className="text-gray-700">
        Please fill in the fields to update your Meter name.
      </p>
      <Form form={form} size="large" layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Meter Name"
          name="name"
          rules={[{ required: true, message: "Please enter a name" }]}
        >
          <Input
            prefix={<AiOutlineUser className="text-primary" size={20} />}
            placeholder="Enter a new name for your device"
          />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          style={{
            backgroundColor: "black",
            borderColor: "black",
            color: "white",
            width: "100%",
            marginTop: "10px",
          }}
        >
          Submit
        </Button>
      </Form>
    </Modal>
  );
};

export default RenameModal;
