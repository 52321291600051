import DataTable from "react-data-table-component";
import moment from "moment";
import { HiUserGroup } from "react-icons/hi";
import { tableCustomStyles } from "../../../helperFuncs";

const PaymentPlanTableMain = ({ data = [], setdetails, setmodal }) => {
  const columns = [
    {
      name: "S/N",
      selector: (row, index) => index + 1,
      sortable: true,
      center: true,
      width: "80px",
    },
    {
      name: "Plan Name",
      selector: (row) => row.planalias,
      sortable: true,
      center: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      center: true,
      cell: (row) => (
        <div className="text-secondary">₦{row.amount.toLocaleString()}</div>
      ),
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      center: true,
    },
    {
      name: "Active period",
      selector: (row) => row.duration,
      sortable: true,
      center: true,
      cell: (row) => (
        <div>
          {row.duration} {row.durationlength}
          {+row.duration > 1 ? "s" : ""}
        </div>
      ),
    },
    {
      name: "Date created",
      selector: (row) => row.datecreated,
      sortable: true,
      center: true,
      cell: (row) => (
        <div className="flex items-center justify-center">
          <div>{moment(row.datecreated).format("lll")}</div>
        </div>
      ),
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <div className="flex gap-2">
          <div className="border-[1px] hidden rounded border-orange-600 text-[12px] text-orange-600 font-light px-4 py-2">
            Edit
          </div>
          <div
            onClick={() => {
              setdetails(row);
              setmodal(true);
            }}
            className="border-[1px] rounded border-secondary text-[12px] text-secondary font-light px-4 py-2 cursor-pointer"
          >
            View
          </div>
        </div>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "50px",
        "&:nth-of-type(even)": {
          backgroundColor: "#f9fafb",
        },
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        fontWeight: "600",
        color: "#1f2937",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  const NoDataComponent = () => (
    <div className="h-[70vh] w-full">
      <div className="flex items-center justify-center mt-[200px]">
        <HiUserGroup size={30} />
      </div>
      <div className="font-normal text-[15px] text-center">
        You don't have any request
      </div>
    </div>
  );

  return (
    <DataTable
      columns={columns}
      data={data}
      customStyles={tableCustomStyles}
      pagination
      responsive
      highlightOnHover
      pointerOnHover
      noDataComponent={<NoDataComponent />}
      persistTableHead
    />
  );
};

export default PaymentPlanTableMain;
