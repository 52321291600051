import axios from "../../utils/axios";
import { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import Rule from "../../components/Rule/Index";
import TableSkimmer from "../../components/TableSkimmer";
import WalletCard from "../../components/Wallet/Cards/WalletCards";
import WalletHistoryTable from "../../components/Wallet/Table/WalletHistoryTable";
import { channelController } from "../../controllers/channelController";
import { errorBlock } from "../../controllers/errorBlock";
import { getToken } from "../../utils/token";
import DateRangePicker from "../../components/DateRangePicker";
import moment from "moment";
import { toast } from "react-hot-toast";
import { billingController } from "../../controllers/BillingController";
import Skimmer from "../../components/Loader/Skimmer";
import { currencyOptions } from "./walletData";
import { useDispatch, useSelector } from "react-redux";
import {
  getWalletBalance,
  getWallethistory,
} from "../../redux/middleware/billing.action";

const Wallet = () => {
  const dispatch = useDispatch();

  const wallet = useSelector((state) => state.billing);

  const [balance, setbalance] = useState(0);
  const [details, setdetails] = useState(null);
  const [refresh, setrefresh] = useState(false);
  const [startdate, setstartdate] = useState(
    moment(Date.now()).subtract(1, "month").format("YYYY-MM-DD HH:mm:ss")
  );
  const [enddate, setenddate] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );
  const [limit, setlimit] = useState(12);
  const [offset, setoffset] = useState(0);
  const [currency, setcurrency] = useState(1);
  const [modal, setmodal] = useState(false);
  // history;
  useEffect(() => {
    dispatch(getWallethistory(startdate, enddate, limit, offset));
  }, [refresh, limit, offset]);

  // balance
  useEffect(() => {
    dispatch(getWalletBalance(currencyOptions[currency].tag));
  }, [refresh, limit, offset, currency]);

  return (
    <>
      <div className="bg-white rounded-md drop-shadow-md p-3 mt-4 mb-10">
        <div className="flex mb-3 justify-end items-center">
          <DateRangePicker
            refreshBtn={setrefresh}
            setEndDate={setenddate}
            setStartDate={setstartdate}
            desc
          />
        </div>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
          <WalletCard
            value={wallet?.balance}
            title={"Wallet Balance"}
            type={"balance"}
            currencyOptions={currencyOptions}
            currency={currency}
            setcurrency={setcurrency}
          />
          <WalletCard
            value={balance}
            title={"Total Money in"}
            currencyOptions={currencyOptions}
            currency={currency}
            setcurrency={setcurrency}
          />
          <WalletCard
            value={balance}
            title={"Total Money out"}
            type={"spent"}
            currencyOptions={currencyOptions}
            currency={currency}
            setcurrency={setcurrency}
          />
        </div>
        <div className="mt-3">
          <div className="">
            <div className="grid grid-cols-1 md:grid-cols-2">
              <PageTitle
                title={"Wallet history"}
                caption={"View wallet transaction history"}
              />
              <div className="hidden">
                <DateRangePicker
                  refreshBtn={setrefresh}
                  setEndDate={setenddate}
                  setStartDate={setstartdate}
                  desc
                />
              </div>
            </div>
          </div>

          <Rule />
          <div className="">
            {wallet?.loading ? (
              <TableSkimmer entries={12} col={7} />
            ) : (
              <WalletHistoryTable
                letHistoryTable
                setdetails={setdetails}
                setmodal={setmodal}
                data={wallet?.walletHistory}
              />
            )}
          </div>
        </div>
      </div>
      <div className="h-[200px]"></div>
    </>
  );
};

export default Wallet;
