import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Badge } from "antd";
import {
  HomeOutlined,
  AppstoreOutlined,
  LineChartOutlined,
  ApiOutlined,
  BellOutlined,
} from "@ant-design/icons";

export const MobileFooter = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const menuItems = [
    {
      key: "home",
      icon: <HomeOutlined />,
      label: "Home",
      path: "/dashboard",
    },
    {
      key: "device",
      icon: <AppstoreOutlined />,
      label: "Meters",
      path: "/allchannels",
    },
    // {
    //   key: "stats",
    //   icon: <LineChartOutlined />,
    //   label: "Add Meter",
    //   path: "/statistics",
    // },
    {
      key: "automation",
      icon: <ApiOutlined />,
      label: "Wallets",
      path: "/wallet",
    },
    {
      key: "notification",
      icon: <BellOutlined />,
      label: "Settings",
      path: "/mobile/settings",
      badge: 5, // Add badge count if needed
    },
  ];

  const handleClick = (path) => {
    // Add ripple effect here if desired
    navigate(path);
  };

  return (
    <div className="w-full flex items-center justify-between px-4 bg-white border-t border-gray-200">
      {menuItems.map((item) => (
        <div
          key={item.key}
          className="flex flex-col items-center justify-center cursor-pointer transition-all duration-300 ease-in-out hover:scale-110"
          onClick={() => handleClick(item.path)}
        >
          <div
            className={`relative text-xl \${
              location.pathname.includes(item.path)
                ? "text-blue-500"
                : "text-gray-500"
            }`}
          >
            {item.badge ? (
              <Badge count={item.badge} size="small">
                {item.icon}
              </Badge>
            ) : (
              item.icon
            )}
          </div>
          <div
            className={`text-xs mt-1 \${
              location.pathname.includes(item.path)
                ? "text-blue-500"
                : "text-gray-500"
            }`}
          >
            {item.label}
          </div>
        </div>
      ))}
    </div>
  );
};
