import { useEffect, useState } from "react";
import BackBtn from "../../../components/BackBtn";
import AddPlanToChannelTable from "../../../components/Channels/Tables/AddPlanToChannelTable";
import Layout from "../../../components/Layout";
import { errorBlock } from "../../../controllers/errorBlock";
import axios from "../../../utils/axios";
import { channelController } from "../../../controllers/channelController";
import { toast } from "react-hot-toast";

const AddPlanToChannel = () => {
  const [allplans, setallplans] = useState([]);
  const [loadingallplans, setloadingallplans] = useState([]);
  // all plans
  useEffect(() => {
    (async () => {
      try {
        setloadingallplans(true);
        let res = await axios.post("/submeter/paymentplan/getallpaymentplans", {
          planalias: "",
        });

        setallplans(res?.data.body);
        setloadingallplans(false);
        // setusers();
      } catch (err) {
        errorBlock(err);
        setloadingallplans(false);
      }
    })();
    return () => {};
  }, []);

  return (
    <>
      <div className="mt-2">
        <BackBtn text={"Go back"} />
        <div className="rounded-md h-[80vh] bg-white rounde-sm shadow-md px-2 py-1  mb-4">
          <div className="">
            Select a payment plan you would like to add to your meter
          </div>
          <AddPlanToChannelTable data={allplans} />
        </div>
      </div>
    </>
  );
};

export default AddPlanToChannel;
