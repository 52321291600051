import { IoNotificationsOutline } from "react-icons/io5";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoMdArrowDropdown } from "react-icons/io";
import { deleteToken, getToken } from "../../utils/token";
import { toast } from "react-hot-toast";
import { useState, useEffect } from "react";
import {
  useLocation,
  Link,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Button } from "../Button";
import AddUser from "../Modals/AddUser";
import AddCustomer from "../Modals/AddCustomer";
import { HiOutlineUserPlus } from "react-icons/hi2";
import axios from "../../utils/axios";
import { errorBlock } from "../../controllers/errorBlock";
import SideNav from "../SideNav";
import MobileSideNav from "../MobileSideNav";
import Backdrop from "../Backdrop";
import { useDispatch } from "react-redux";
import { postLogout } from "../../redux/middleware/auth.action";
import { useMQTT } from "../../context/MqttContext";
import TopNavTitle from "./TopNavTitle";

import { Badge, Avatar, Dropdown, Menu } from "antd";
import {
  UserOutlined,
  BellOutlined,
  PlusOutlined,
  MenuOutlined,
  DownOutlined,
  UserAddOutlined,
  LoginOutlined,
} from "@ant-design/icons";

const TopNav = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [requestCount, setRequestCount] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { mqttEvents } = useMQTT();

  useEffect(() => {
    (async () => {
      try {
        const userRole = await getToken("spiral_token");
        setRole(userRole);
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const data = JSON.parse(localStorage.getItem("spiral_notification"));
        setNotificationCount(!data ? 0 : data.length);
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, [refresh, notificationCount]);

  useEffect(() => {
    mqttEvents.on("notification", () => {
      const data = JSON.parse(localStorage.getItem("spiral_notification"));
      setNotificationCount(!data ? 0 : data.length);
    });
  }, [mqttEvents]);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.post(
          "/submeter/getreceivedchanneladdrequests",
          {
            limit: 100,
            offset: 0,
          }
        );
        setRequestCount(res?.data.meta.count);
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, []);

  const menu = [
    {
      key: "1",
      label: (
        <div
          className="flex items-center justify-between"
          onClick={() => {
            navigate("/settings");
          }}
        >
          <UserAddOutlined />
          <p className="m-0 mr-6 px-3">Profile</p>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          className="flex items-center justify-between"
          onClick={() => {
            localStorage.clear();
            window.location.href = "/";
          }}
        >
          <LoginOutlined />
          <p className="m-0 mr-6 px-3">Log out </p>
        </div>
      ),
    },
  ];

  const title = () => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("name")) return searchParams.get("name");

    switch (pathname) {
      case "/dashboard":
        return "Dashboard";
      case "/allmeters":
        return "Meters";
      case "/allchannels":
        return "Channels";
      case "/meterclass":
        return "Meterclass";
      case "/wallet":
        return "Wallets";
      case "/settings":
        return "Profile";
      case "/notification":
        return "Notifications";
      case "/request":
        return "Requests";
      case "/mobile/settings":
        return "Settings";
      case "/allpaymentplans":
        return "Payment plans";
      default:
        return "";
    }
  };

  return (
    <div className="flex px-4 items-center justify-between h-[70px] drop-shadow-sm bg-white sticky top-0 left-0">
      {/* Page Title */}
      <div>
        <h2 className="font-semibold text-md">{title()}</h2>
      </div>

      {/* Right Section */}
      <div className="flex items-center">
        {/* Add Customer Button */}
        {role === "Project Manager" && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            className="mr-4"
            onClick={() => console.log("Add Customer")}
          >
            Add Customer
          </Button>
        )}

        {/* Add User Button */}
        {role === "Customer Manager" && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            className="mr-4"
            onClick={() => console.log("Add User")}
          >
            Add User
          </Button>
        )}

        {/* Requests */}
        <Badge count={requestCount} className="!mr-6">
          <Link to="/request" className="mx-3">
            <UserOutlined
              twoToneColor={"black"}
              style={{ fontSize: 20, color: "black" }}
            />
          </Link>
        </Badge>

        {/* Notifications */}
        <Badge count={notificationCount}>
          <Link to="/notification" className="!mx-3 ">
            <BellOutlined
              twoToneColor={"black"}
              style={{ fontSize: 20, color: "black" }}
            />
          </Link>
        </Badge>

        {/* Profile Dropdown */}
        <Dropdown
          className=" cursor-pointer"
          menu={{
            items: menu,
          }}
          trigger={["hover"]}
        >
          <Avatar size={35} className="mr-2">
            J
          </Avatar>
        </Dropdown>
      </div>
    </div>
  );
};

export default TopNav;
