import DataTable from "react-data-table-component";
import moment from "moment";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import Rule from "../../components/Rule/Index";
import { FcCalendar } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import {
  RightOutlined,
  BellOutlined,
  LockOutlined,
  GlobalOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  NotificationFormatter,
  alertFormatter,
  tableCustomStyles,
} from "../../helperFuncs";
import NotiImg from "../../assets/svg/2246841_bell_notification_one notification_twitter_icon.svg";
import BackBtn from "../../components/BackBtn";
import { useMQTT } from "../../context/MqttContext";

const Notification = () => {
  const [noti, setnoti] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const navigate = useNavigate();
  const { mqttEvents } = useMQTT();

  useEffect(() => {
    (async () => {
      try {
        let data = await JSON.parse(
          localStorage.getItem("spiral_notification")
        );
        setnoti(data);
      } catch (err) {
        toast.error(err.message);
      }
    })();
    return () => {};
  }, [refresh, localStorage.length]);

  useEffect(() => {
    (async () => {
      try {
        mqttEvents.on("notification", () => {
          let data = JSON.parse(localStorage.getItem("spiral_notification"));
          setnoti(data);
        });
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, []);

  const columns = [
    {
      name: "Date received",
      selector: (row) => row.datetime || Date.now(),
      sortable: true,
      cell: (row) => (
        <div className="flex justify-start items-center">
          <FcCalendar size={21} />
          <div className="pl-2">
            {row.datetime
              ? moment(row.datetime).format("lll")
              : moment(Date.now()).format("lll")}
          </div>
        </div>
      ),
    },
    {
      name: "Device Id",
      selector: (row) => row.deviceid,
      sortable: true,
      center: true,
    },
    {
      name: "Alert type",
      selector: (row) => row.type,
      sortable: true,
      center: true,
      cell: (row) =>
        NotificationFormatter(row.type) === "Unknown Command"
          ? alertFormatter(row.alerttype).title
          : NotificationFormatter(row.type),
    },
    {
      name: "Action",
      cell: (row) => (
        <div
          onClick={async () => {
            try {
              let filteredNoti = noti.filter(
                (i) => i?.transactionid !== row.transactionid
              );
              await localStorage.setItem(
                "spiral_notification",
                JSON.stringify(filteredNoti)
              );
              setrefresh((prev) => !prev);
              navigate(`/channel/\${row.deviceid}`);
            } catch (err) {
              toast.error(err.message);
            }
          }}
          className="border-[1px] text-secondary text-[13px] border-secondary p-1 rounded-md w-[fit-content] cursor-pointer hover:bg-secondary hover:text-white"
        >
          View details
        </div>
      ),
      center: true,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "52px",
        "&:nth-of-type(even)": {
          backgroundColor: "#f9fafb",
        },
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        fontWeight: "600",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  const NoDataComponent = () => (
    <div className="flex items-center justify-center h-full">
      <div className="">
        <div className="flex items-center justify-center">
          <img src={NotiImg} alt="" className="w-[70px] h-[70px]" />
        </div>
        <div className="font-Kanit mt-3 font-semibold">
          You don't have any notification at the moment.
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="bg-white drop-shadow-md rounded-md mt-4">
        {/* <div className="px-4">
          <BackBtn />
          <PageTitle title={"Notifications"} caption={""} />
        </div> */}
        {/* <Rule /> */}
        <div className="border-gray-300 h-[90vh] md:flex hidden rounded-md p-3">
          <DataTable
            columns={columns}
            data={noti || []}
            customStyles={tableCustomStyles}
            pagination
            responsive
            highlightOnHover
            pointerOnHover
            noDataComponent={<NoDataComponent />}
            persistTableHead
          />
        </div>
        <div className="md:hidden block">
          {Array.from(Array(10)).map((_, i) => (
            <div
              role="button"
              // onClick={() => navigate(item.link)}
              key={i}
              className="flex items-center justify-between p-3 bg-white  border hover:bg-gray-50 cursor-pointer transition-colors"
            >
              <div className="flex flex-col items-center">
                <p className="text-md m-0  font-semibold">Air conditioner</p>
                <p className="m-0 ml-2 text-gray-900">Power state of device </p>
              </div>
              <RightOutlined className="text-gray-400 text-[10px]" />
            </div>
          ))}
          <div className="text-center p-3 underline">View more</div>
        </div>
      </div>
      <div className="h-[200px]"></div>
    </>
  );
};

export default Notification;
