import moment from "moment";
import DateRangePicker from "../../../components/DateRangePicker";
import Layout from "../../../components/Layout";
import PageTitle from "../../../components/PageTitle";
import Rule from "../../../components/Rule/Index";
import { useContext, useEffect, useState } from "react";
import EnergyBarChart from "../../../components/Energy/Charts/EnergyBarChart";
import { FiBarChart2 } from "react-icons/fi";
import BackBtn from "../../../components/BackBtn";
import { errorBlock } from "../../../controllers/errorBlock";
import { ChannelContext } from "../../../context/channelContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import ProcessingCard from "../../../components/Loader/ProcessingCard";
import { useMQTT } from "../../../context/MqttContext";
import axios from "../../../utils/axios";
import { commandController } from "../../../controllers/CommandController";
import { getToken } from "../../../utils/token";
import { BsArrowsFullscreen } from "react-icons/bs";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
const EnergyAnalytics = () => {
  const [showscreen, setshowscreen] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { getEnergyConsumption } = useContext(ChannelContext);
  const [startdate, setstartdate] = useState(
    moment(Date.now()).subtract("1", "day").format("YYYY-MM-DD HH:mm:ss")
  );
  const { client } = useMQTT();
  const [enddate, setenddate] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );
  const [data, setdata] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [loading, setloading] = useState(false);
  // energy
  useEffect(() => {
    (async () => {
      try {
        let res = await getEnergyConsumption(id, startdate, enddate);
        setdata(res?.body);
      } catch (err) {
        toast.error(err.message);
      }
    })();
    return () => {};
  }, [refresh]);

  const handleRealTimeUpdate = async () => {
    try {
      setloading(true);
      console.log(`r_${id}`, "meter id", client);
      client.subscribe("up");
      client.subscribe(`r_${id}`);
      let user = await getToken("voltz_user_username");
      let res = await axios.post("/commands/realtimeupdate", {
        meterid: id,
        transactiontopic: `r_${id}`,
      });
      let result = commandController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setloading(false);
        return;
      }
      setloading(true);
      return navigate(`/channel/realtimeconsumption/${id}`);
    } catch (err) {
      setloading(false);

      if (err.response) {
        toast.error(err.response.data.response);
        setloading(false);
        return;
      }
      if (err.message.includes("AMQJS0011E Invalid state not connected")) {
        return toast.error("Unable to connect to broker. Please try again");
      }
      toast.error(err.message);
    }
  };

  const handleFS = useFullScreenHandle();

  return (
    <>
      {loading && <ProcessingCard open={loading} />}
      <div className="mt-6">
        <div className="bg-white drop-shadow-md p-3 rounded-md h-auto ">
          <div className="flex items-center justify-between">
            <BackBtn text={"Go back to device page"} />

            <div
              // to={`/channel/realtimeconsumption/${id}`}
              onClick={handleRealTimeUpdate}
              className="flex mx-2 md:mx-0 my-2 align-center justify-start w-[fit-content] bg-secondary rounded-md px-2 py-2 cursor-pointer"
            >
              <div className="flex align-center justify-center pt-[1px]">
                <FiBarChart2 color="white" size={21} />
              </div>
              <div className="text-white font-light">
                Request for realtime consumption
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row align-items justify-between">
            <PageTitle
              title={"Energy consumption"}
              caption={"Insight on energy you've consumed"}
            />
            <div className="">
              <DateRangePicker
                setStartDate={setstartdate}
                setEndDate={setenddate}
                refreshBtn={setrefresh}
                desc={"Pick a date range"}
              />
            </div>
          </div>

          <Rule />

          <div className="relative">
            <EnergyBarChart data={data} />
          </div>
        </div>
      </div>
    </>
  );
};

export default EnergyAnalytics;
