import React from "react";
import {
  RightOutlined,
  BellOutlined,
  LockOutlined,
  GlobalOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Avatar } from "antd";
import { useNavigate } from "react-router-dom";

const MobileProfile = () => {
  const navigate = useNavigate();
  // Sample data - replace with actual data
  const userInfo = {
    name: "Nico Samuel",
    email: "nicosamuel@gmail.com",
    stats: [
      { label: "Device", count: 12 },
      { label: "Room", count: 4 },
      { label: "House", count: 3 },
    ],
  };

  const menuItems = [
    {
      icon: <BellOutlined />,
      label: "Notification",
      link: "/notification",
    },
    {
      icon: <BellOutlined />,
      label: "All Request",
      link: "/request",
    },
    {
      icon: <LockOutlined />,
      label: "Security",
      link: "/security",
    },
    {
      icon: <GlobalOutlined />,
      label: "Language",
      link: "/language",
    },
    {
      icon: <SettingOutlined />,
      label: "Payment plans",
      link: "/allpaymentplans",
    },
    // {
    //   icon: <SettingOutlined />,
    //   label: "More Settings",
    //   link: "/settings",
    // },
  ];

  return (
    <div className="flex flex-col h-full bg-white">
      {/* Profile Section */}
      <div className="p-4 border-b">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center">
            <Avatar size={50} src="https://via.placeholder.com/50" />
            <div className="ml-3">
              <h3 className="text-lg font-semibold text-gray-900">
                {userInfo.name}
              </h3>
              <p className="text-sm text-gray-600">{userInfo.email}</p>
            </div>
          </div>
          <RightOutlined className="text-gray-400" />
        </div>

        {/* Stats */}
        <div className="flex justify-between mt-4 bg-gray-50 rounded-lg p-3">
          {userInfo.stats.map((stat, index) => (
            <div key={index} className="text-center">
              <h4 className="text-xl font-semibold text-gray-900">
                {stat.count}
              </h4>
              <p className="text-sm text-gray-600">{stat.label}</p>
            </div>
          ))}
        </div>
      </div>

      {/* General Settings */}
      <div className="p-4">
        <h3 className="text-lg font-semibold text-gray-900 mb-4">
          General Settings
        </h3>
        <div className="space-y-3">
          {menuItems.map((item, index) => (
            <div
              role="button"
              onClick={() => navigate(item.link)}
              key={index}
              className="flex items-center justify-between p-3 bg-white rounded-lg border hover:bg-gray-50 cursor-pointer transition-colors"
            >
              <div className="flex items-center">
                <span className="text-xl text-gray-600">{item.icon}</span>
                <span className="ml-3 text-gray-900">{item.label}</span>
              </div>
              <RightOutlined className="text-gray-400 text-[10px]" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileProfile;
