import React, { useState } from "react";
import { Modal, Form, Input, Button, notification } from "antd";
import axios from "../../../utils/axios";
import { getToken } from "../../../utils/token";
import { channelController } from "../../../controllers/channelController";
import { toast } from "react-hot-toast";

const AddMeter = ({ visible, closeBtn, refreshBtn }) => {
  const [loading, setLoading] = useState(false);

  const handleAddDevice = async (values) => {
    try {
      setLoading(true);
      const { meterName, meterId } = values;

      let username = await getToken("spiral_username");
      if (!username) return null;

      let res = await axios.post("/submeter/adddevice", {
        deviceid: meterId,
        alias: meterName,
        username,
      });

      let result = channelController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setLoading(false);
        return;
      }

      toast[result.type]("Device added successfully");
      refreshBtn((prev) => !prev); // Trigger refresh
      closeBtn(false); // Close modal
    } catch (err) {
      toast.error(err.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Add Meter"
      visible={visible}
      onCancel={() => closeBtn(false)}
      footer={null}
    >
      <p className="m-0">Fill in the options below to add a meter.</p>
      <Form
        size="large"
        layout="vertical"
        onFinish={handleAddDevice}
        initialValues={{
          meterName: "",
          meterId: "",
        }}
      >
        <Form.Item
          label="Meter Name"
          name="meterName"
          rules={[
            {
              required: true,
              message: "Please provide a name for the meter.",
            },
          ]}
        >
          <Input placeholder="Enter meter name" />
        </Form.Item>

        <Form.Item
          label="Meter Number"
          name="meterId"
          rules={[
            {
              required: true,
              message: "Please provide the meter number.",
            },
          ]}
        >
          <Input placeholder="Enter meter number" />
        </Form.Item>

        <Form.Item className="mt-4">
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
            block
            className="w-full mt-4 !bg-primary !outline-none !border-none"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddMeter;
