import Layout from "../../../components/Layout";
import PowerImgBg from "../../../assets/png/18410.jpg";
import DateRangePicker from "../../../components/DateRangePicker";
import { Select } from "antd";
import { dateOptions } from "../../../constant";
import MBarCharts from "../../../components/Channels/Charts/MBarChart";
import PaymentPlanTable from "../../../components/PaymentPlan/PaymentPlanTable";
import { IoPower } from "react-icons/io5";
import CommandsTable from "../../../components/Commands/Tables/CommandsTable";
import { FaUsers, FaUsersCog } from "react-icons/fa";
import { useContext, useEffect, useState } from "react";
import { ChannelContext } from "../../../context/channelContext";
import { errorBlock } from "../../../controllers/errorBlock";
import { Link, useNavigate, useParams } from "react-router-dom";
import Skimmer from "../../../components/Loader/Skimmer";
import moment from "moment";
import NoPlanImg from "../../../assets/svg/7952173_receipt_paper_transation_check_sale_icon.svg";
import Drawer from "../../../components/Drawer";
import ModalCover from "../../../components/ModalCover";
import { VscClose } from "react-icons/vsc";
import ScheduleImg from "../../../assets/png/Screenshot 2023-03-13 at 21.05.17.png";
import CON from "../../../components/Commands/CON";
import ShareChannel from "../../../components/Channels/ShareChannel";
import BillingModal from "../../../components/Channels/Modals/BillingModal";
import { GiTakeMyMoney } from "react-icons/gi";
import CommandsTab from "../../../components/Channels/Modals/CommandTabs";
import ConsumptionModal from "../../../components/Channels/Modals/ConsumptionModal";
import LoopTimer from "../../../components/Channels/Drawer/LoopTimer";
import END from "../../../components/Channels/Modals/END";
import BackBtn from "../../../components/BackBtn";
import PowerStateCard from "../../../components/Channels/PowerStateCard";
import { toast } from "react-hot-toast";
import { useMQTT } from "../../../context/MqttContext";
import { channelController } from "../../../controllers/channelController";
import { getToken } from "../../../utils/token";
import axios from "../../../utils/axios";
import { CiSettings } from "react-icons/ci";
import { IoIosSettings } from "react-icons/io";
import NocommandsImg from "../../../assets/png/Screenshot 2023-04-05 at 17.58.00.png";
import { BiLineChart } from "react-icons/bi";
import { Button } from "../../../components/Button";
import TableSkimmer from "../../../components/TableSkimmer";
import { themeColor } from "../../../constant/color";
import BuyPlan from "../../../components/Channels/Modals/BuyPlan";
import { NotificationFormatter } from "../../../helperFuncs";
import CommandCard from "../../../components/Channels/Cards/CommandCard";
import PaymentPlanCard from "../../../components/Channels/Cards/PaymentPlanCard";
import { BillingContext } from "../../../context/BillingContext";
import ChannelPaymentPlanTable from "../../../components/Channels/Tables/ChannelPaymentPlanTable";
import UserImg from "../../../assets/png/icons8-users-100.png";
import ChannelEnergyConsumption from "../../../components/Channels/Charts/ChannelEnergyConsumption";
import AdvancedSettings from "../Modals/AdvancedSettings";
import AllCommandsOnChannel from "../../../components/Channels/Tables/AllCommandsOnChannel";
import PaymentPlanStatus from "../../../components/Channels/Modals/PaymentPlanStatus";

const ChannelDetails = () => {
  // ==== plan start
  const [plandetails, setplandetails] = useState({});
  const [planmodal, setplanmodal] = useState(false);
  // ==== plan end
  const { id } = useParams();
  const [startdate, setstartdate] = useState(
    moment(Date.now()).startOf("month").format("YYYY-MM-DD HH:mm:ss")
  );

  const [username, setusername] = useState("");
  const [optionssection, setoptionssection] = useState(false);
  const { mqttEvents } = useMQTT();
  const [endmodal, setendmodal] = useState(false);
  const [billingmodal, setbillingmodal] = useState(false);
  const [ischannelowner, setischannelowner] = useState(false);
  const [sharemodal, setsharemodal] = useState(false);
  const [tabstate, settabstate] = useState(2);
  const [openshedule, setopenshedule] = useState(false);
  const [opensheduledrawer, setopensheduledrawer] = useState(false);
  const [selectedday, setselectedday] = useState("today");
  const [selectedpowerstate, setselectedpowerstate] = useState("");
  const [commandsModal, setcommandsModal] = useState(false);
  const [enddate, setenddate] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );
  const navigate = useNavigate();
  const [consumptionmodal, setconsumptionmodal] = useState(false);
  const [startdateenergy, setstartdateenergy] = useState(
    moment(Date.now()).startOf("week").format("YYYY-MM-DD HH:mm:ss")
  );
  const [loopmodal, setloopmodal] = useState(false);
  const [enddateenergy, setenddateenergy] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );
  // power state
  const [showButton, setshowButton] = useState(false);
  const [powerstatus, setpowerstatus] = useState(1);

  const [energydata, setenergydata] = useState({
    max: 0,
    data: [],
  });
  const { getBillingData } = useContext(ChannelContext);
  const [updated, setupdated] = useState(false);
  const [commands, setcommands] = useState([]);
  const [loadingPlan, setloadingPlan] = useState(false);
  const [usercommands, setusercommands] = useState([]);
  const [allcommands, setallcommands] = useState([]);
  const {
    getChannelInfo,
    getUsersonChannel,
    getAllPaymentPlanOnChannel,
    getCommandsByUser,
    getEnergyConsumption,
  } = useContext(ChannelContext);
  const [loading, setloading] = useState({
    channel: false,
    user: true,
    plans: true,
    commands: true,
    energy: false,
  });
  const [billdetails, setbilldetails] = useState(null);

  const [planstatus, setplanstatus] = useState(false);
  const [plans, setplans] = useState([]);
  const [allplans, setallplans] = useState([]);
  const [loadingallplans, setloadingallplans] = useState([]);
  const [channelData, setchannelData] = useState({});
  const [recallplans, setrecallplans] = useState(false);
  const [users, setusers] = useState({
    count: 0,
    data: [],
  });
  const [tablestate, settablestate] = useState(1);
  mqttEvents.setMaxListeners(30);
  // billing status
  useEffect(() => {
    (async () => {
      try {
        let result = await getBillingData(id);
        setbilldetails(result);
        // console.log(result);
      } catch (err) {
        toast.error(err.message);
      }
    })();
    return () => {};
  }, []);
  // channelInfo
  useEffect(() => {
    (async () => {
      try {
        setloading((prev) => {
          return {
            ...prev,
            channel: true,
          };
        });
        let res = await getChannelInfo(id);
        setchannelData(res?.body[0]);
        setloading((prev) => {
          return {
            ...prev,
            channel: false,
          };
        });
      } catch (err) {
        errorBlock(err);
        setloading((prev) => {
          return {
            ...prev,
            channel: false,
          };
        });
      }
    })();
    return () => {};
  }, []);
  // users on channel
  useEffect(() => {
    (async () => {
      try {
        let res = await getUsersonChannel(id);
        setusers((prev) => {
          return {
            ...prev,
            count: res?.meta.count || 0,
          };
        });
        setloading((prev) => {
          return {
            ...prev,
            user: false,
          };
        });
      } catch (err) {
        errorBlock(err);
        setloading((prev) => {
          return {
            ...prev,
            user: false,
          };
        });
      }
    })();
    return () => {};
  }, []);
  // all plans
  useEffect(() => {
    (async () => {
      try {
        setloadingallplans(true);
        let res = await axios.post("/submeter/paymentplan/getallpaymentplans", {
          planalias: "",
        });

        setallplans(res?.data.body);
        setloadingallplans(false);
        // setusers();
      } catch (err) {
        errorBlock(err);
        setloadingallplans(false);
      }
    })();
    return () => {};
  }, []);
  // plans
  useEffect(() => {
    (async () => {
      try {
        setloadingPlan(true);
        let res = await axios.post(
          "/submeter/paymentplanassignment/getallassignedplans",
          {
            channelid: id,
          }
        );

        setplans(res?.data.body);
        // setusers();
        setloadingPlan(false);
      } catch (err) {
        errorBlock(err);
        setloadingPlan(false);
      }
    })();
    return () => {};
  }, [recallplans]);
  // commands
  useEffect(() => {
    (async () => {
      try {
        let sender = await getToken("spiral_username");
        if (!sender) return null;
        setloading((prev) => {
          return {
            ...prev,
            commands: true,
          };
        });
        Promise.all([
          axios.post("/commandanalytics/getissuedcommandsbydeviceid", {
            deviceid: id,
            limit: 10,
            offset: 0,
            startissueddate: moment(Date.now())
              .startOf("year")
              .format("YYYY-MM-DD HH:mm:ss"),
            endissueddate: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
          }),
          axios.post("/commandanalytics/getissuedcommandsbyusername", {
            sender,
            limit: 10,
            offset: 0,
            startissueddate: moment(Date.now())
              .startOf("year")
              .format("YYYY-MM-DD HH:mm:ss"),
            endissueddate: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
          }),
        ])
          .then((res) => {
            setusercommands(res[0].data.body);
            setallcommands(
              res[1].data.body.length > 10
                ? res[1].data.body.splice(0, 10)
                : res[1].data.body.length == 0
                ? []
                : res[1].data.body
            );
            setloading((prev) => {
              return {
                ...prev,
                commands: false,
              };
            });
          })
          .catch((err) => {
            toast.error(err.message);
            setloading((prev) => {
              return {
                ...prev,
                commands: false,
              };
            });
          });
        // setcommands(res?.body);
        // setusers();
      } catch (err) {
        errorBlock(err);
        setloading((prev) => {
          return {
            ...prev,
            commands: false,
          };
        });
      }
    })();
    return () => {};
  }, []);
  // energy
  useEffect(() => {
    (async () => {
      try {
        let res = await getEnergyConsumption(
          id,
          startdateenergy,
          enddateenergy
        );
        setenergydata((prev) => {
          return {
            data: res?.body,
            max: res?.meta?.totalactiveenergyln1,
          };
        });

        console.log(res, "energy data");
        // setusers();
        setloading((prev) => {
          return {
            ...prev,
            energy: false,
          };
        });
      } catch (err) {
        errorBlock(err);
        setloading((prev) => {
          return {
            ...prev,
            energy: false,
          };
        });
      }
    })();
    return () => {};
  }, [updated]);
  // GEN;
  useEffect(() => {
    (async () => {
      let user = await getToken("spiral_username");
      try {
        let res = await axios.post("/commands/getpowerstatesofmeter", {
          meterid: id,
          transactiontopic: `mqtt_${user}`,
        });
        let result = channelController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);
          return;
        }

        // cons;
        mqttEvents.on("message", (message) => {
          if (message.topic === `mqtt_${user}`) {
            if (!message.payloadString) return null;
            if (JSON.parse(message.payloadString).type == "GEN") {
              setshowButton((prev) => true);
              setpowerstatus(JSON.parse(message.payloadString).status);
            }
          }
        });
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.response);
          return;
        }
        toast.error(err.message);
      }
    })();
  }, []);
  // is channel owner
  useEffect(() => {
    (async () => {
      let username = await getToken("spiral_username");
      setusername(username);
      try {
        if (!username) return null;
        let res = await axios.post("/submeter/isdeviceownerbychannelid", {
          username,
          channelid: id,
        });
        let result = channelController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);
          return;
        }
        setischannelowner(res.data.response);
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.response);
          return;
        }
        toast.error(err.message);
      }
    })();
  }, []);
  return (
    <>
      <div className="mt-2">
        <BackBtn text={"Go back"} />
        {/* 1 vp */}
        <div className=" rounded-md bg-white rounde-sm shadow-md px-2 py-1  mb-4">
          <div className="flex items-center justify-between mt-2">
            <div className="flex items-center ">
              <div className=" w-[15px] h-[25px] bg-[#e0c5ff] rounded-sm"></div>
              <div className="font-semibold ml-2">Overview</div>
            </div>
            <div className="flex items-center justify-between">
              {ischannelowner && (
                <div
                  onClick={() => {
                    setoptionssection((prev) => !prev);
                  }}
                  className="relative w-[fit-content py-1 px-3 mr-3 bg-secondary border-[1px] rounded-sm border-white flex items-center justify-center cursor-pointer"
                >
                  <div className="font-normal text-[15px] text-white">
                    Advanced controls
                  </div>
                  {optionssection && (
                    <AdvancedSettings
                      setoptionssection={setoptionssection}
                      setsharemodal={setsharemodal}
                      setbillingmodal={setbillingmodal}
                      setcommandsModal={setcommandsModal}
                    />
                  )}
                </div>
              )}
              <div
                onClick={() => setendmodal(true)}
                className="w-[fit-content py-1 px-3 bg-secondary border-[1px] rounded-sm border-white flex items-center justify-center cursor-pointer"
              >
                <div className="font-normal text-[15px] text-white">
                  Recharge meter
                </div>
              </div>
            </div>
          </div>
          <hr className="border-[0.5px] mt-4 border-gray-300 w-full" />

          {/* )} */}
          <div className="grid grid-cols-1 gap-4 mt-4 md:grid-cols-2 lg:grid-cols-4 mb-6 md:gap-4 lg:gap-2">
            {/* Power state */}
            <PowerStateCard
              setpowerstatus={setpowerstatus}
              showButton={showButton}
              setshowButton={setshowButton}
              powerstatus={powerstatus}
              loading={loading}
              data={channelData}
              setopenshedule={setopenshedule}
            />
            {/* users */}
            <div className="h-[120px] border-b-[2px] border-teal-400 p-2 bg-white rounded-md drop-shadow-md relative">
              <div className="pt-1 flex items-center justify-between">
                <div className="">
                  <div className="font-light text-gray-700">Users on meter</div>
                  <div className="font-semibold w-[80%]">
                    Total numbers of users your meter
                  </div>
                </div>
                <div className="w-[50px] mt-[-16px] h-[50px] hidden items-center justify-center">
                  <img src={UserImg} className="h-full w-full" />
                </div>
              </div>
              {ischannelowner && (
                <Link
                  to={`/channel/usermanagement/${id}`}
                  className="font-normal  text-black text-[13px] pt-1 absolute bottom-1 left-2 underline cursor-pointer w-[60%]"
                >
                  Manage users
                </Link>
              )}
              <div className="flex items-center absolute bottom-[5px] right-[6px] w-[50px] h-[50px]  cursor-pointer justify-center  p-2">
                <div className="font-bold text-3xl">
                  {loading.user ? "" : users.count}
                </div>
              </div>
            </div>
            {/* energy */}
            <div className="h-[120px]  border-b-[2px] border-orange-400 p-2 bg-white rounded-md drop-shadow-md relative">
              <div className="pt-1">
                <div className="font-light text-gray-700 text-[13px]">
                  Consumption{" "}
                </div>
                <div className="font-semibold w-[80%]">
                  Maximum energy reached {selectedday}.
                </div>
              </div>
              <div
                onClick={() => setconsumptionmodal(true)}
                // text-[#7e6eda]
                className="font-normal text-[13px] pt-1 absolute bottom-1 left-2  underline cursor-pointer w-[60%]"
              >
                View consumption
              </div>
              <div className="flex items-center absolute bottom-[10px] right-[15px] drop-shadow-lg cursor-pointer justify-center rounded-fullp-2">
                <div className="font-bold text-3xl">
                  {/* {0} */}
                  {!energydata
                    ? "0.00"
                    : isNaN(energydata?.max)
                    ? 0
                    : parseFloat(energydata?.max).toFixed(2)}
                  <span className="text-[13px]">kWh</span>
                </div>
              </div>
            </div>
            {/* END */}
            <div className="h-[120px] p-2 border-b-[2px] border-secondary bg-white rounded-md drop-shadow-md relative">
              <div className="pt-1">
                <div className="font-light text-gray-700">Monitor energy</div>
                <div className="font-semibold w-[80%]">
                  View consumption on meter in realtime{" "}
                </div>
              </div>

              <Link
                to={`/channel/energy-analytics/${id}`}
                className="flex text-black items-center absolute  cursor-pointer bottom-[5px]"
              >
                <div className="text-[14px] underline">Monitor</div>
              </Link>
              {/* <div className="flex items-center absolute bottom-[15px] right-[15px] w-[50px] h-[50px] bg-white drop-shadow-lg cursor-pointer justify-center rounded-full border-[2px] border-[#5c6bf0] p-2">
                <GiTakeMyMoney color="#5c6bf0" size={24} />
              </div> */}
            </div>
          </div>
        </div>
        {/* 2vp */}
        <div className="bg-white rounded-md drop-shadow-md p-2 mt-2">
          <div className="flex items-center mb-2">
            <div className="w-[15px] h-[25px] bg-[#ffe0a8] rounded-sm"></div>
            <h3 className="mb-0 ml-2">
              View Consumption on meter during weekdays
            </h3>
          </div>
          <div className="h-[400px]">
            <ChannelEnergyConsumption data={energydata.data} />
          </div>
        </div>

        {optionssection && (
          <div className="bg-white hidden p-3 my-3 drop-shadow-sm rounded-lg">
            <div className="flex items-center justify-between">
              <div className="font-semibold font-Kanit text-gray-800">
                Control your device with the options below
              </div>
              <div
                className="underline text-[13px] text-right font-semibold cursor-pointer text-secondary"
                onClick={() => setoptionssection(false)}
              >
                Hide controls
              </div>
            </div>
            <div className="mx-auto w-[90%] p-3 border-[1px] my-3 rounded-md border-gray-200 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-10">
              {/* share channel */}
              <div
                className="add-user cursor-pointer p-2 rounded-lg drop-shadow-md bg-gradient-to-r from-sky-500 to-indigo-500 h-[90px] relative"
                onClick={() => setsharemodal(true)}
              >
                <div className="absolute text-[14px] bottom-2 right-2 px-2 py-1 text-white border-[1px] border-white rounded-md">
                  Add user
                </div>
                <div className="mt-1">
                  <div className="font-semibold text-white ">Share Channel</div>
                  <div className="text-white font-light w-[60%]">
                    Channel access to your channel with a friend.
                  </div>
                </div>
              </div>
              <div
                className="add-user hidden relative rounded-lg drop-shadow-md h-[90px] bg-gradient-to-l from-[#ebfbff] to-[#fefbff] cursor-pointer"
                onClick={() => setbillingmodal(true)}
              >
                <div className="absolute px-2 py-1 text-[14px] bottom-1 right-1 border-[1px] border-gray-700 rounded-lg">
                  Update
                </div>
                <div className="mt-1 p-2">
                  <div className="font-semibold text-grey-800 ">
                    Billing settings
                  </div>
                  <div className="text-grey-800 font-light w-[80%]">
                    Billing Operation has not been activate on your channel
                  </div>
                </div>
              </div>
              {/* view controls */}
              <div
                onClick={() => setcommandsModal(true)}
                className="add-user border-collapse-[2px] rounded-lg drop-shadow-md h-[90px] cursor-pointer p-2"
                style={{
                  background: `url(${PowerImgBg})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <div className="mt-1">
                  <div className="font-semibold text-grey-800 ">
                    View Channel control
                  </div>
                  <div className="text-grey-800 font-light w-[90%]">
                    Click the card to issue more commands on your channel.
                  </div>
                </div>
              </div>
              <div
                className="add-user relative rounded-lg drop-shadow-md h-[90px] bg-gradient-to-l from-[#ebfbff] to-[#fefbff] cursor-pointer"
                onClick={() => setbillingmodal(true)}
              >
                <div className="absolute px-2 py-1 text-[13px] bottom-1 right-1 border-[1px] border-gray-700 rounded-lg">
                  Update
                </div>
                <div className="mt-1 p-2">
                  <div className="font-semibold text-grey-800 ">
                    Billing settings
                  </div>
                  <div className="text-grey-800 font-light w-[80%]">
                    Billing Operation has not been activate on your channel
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* plans on channel */}
        {/* {billdetails?.kwhbilling == "true" && (
          // grid lg:grid-cols-1
          <div className="hidden gap-6 mt-6">
            <div className="bg-white rounded-md p-2 drop-shadow">
              <div className="flex items-center justify-between">
                <div className="font-semibold">Available Plans on channels</div>
                <Link
                  to={"/channel/paymentplan/" + id}
                  className="text-secondary font-normal underline"
                >
                  View all
                </Link>
              </div>
              <div className="h-auto border-[1px] rounded-md border-gray-300 mt-2 px-2">
                {loadingPlan ? (
                  <>
                    <div className="hidden md:block lg:block">
                      <TableSkimmer entries={10} />
                    </div>
                    <div className="block md:hidden">
                      {Array.from(Array(6)).map((_, i) => (
                        <div key={i} className="rounded-3 overflow-hidden my-2">
                          <Skimmer heigth={70} />
                        </div>
                      ))}
                    </div>
                  </>
                ) : plans.length == 0 ? (
                  <div className="flex h-[400px] flex-col items-center justify-center">
                    <div className="w-[60px] h-[60px] ">
                      <img src={NoPlanImg} className="w-full h-full" />
                    </div>
                    <div className="pt-2 font-Kanit font-semibold text-center">
                      No payment plan available on this device at the moment.
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="hidden md:block">
                      <PaymentPlanTable
                        data={plans.length == 0 ? [] : plans}
                        plandetails={plandetails}
                        setplandetails={setplandetails}
                        planmodal={planmodal}
                        setplanmodal={setplanmodal}
                      />
                    </div>
                    <div className="block md:hidden">
                      {plans.map((i, index) => (
                        <PaymentPlanCard
                          data={i}
                          key={index}
                          plandetails={plandetails}
                          setplandetails={setplandetails}
                          planmodal={planmodal}
                          setplanmodal={setplanmodal}
                        />
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="bg-white hidden rounded-md p-2 drop-shadow">
              <div className="flex items-start justify-between">
                <div className="">
                  <div className="font-semibold">
                    Energy consumption on channel
                  </div>
                  <Link
                    to={`/channel/energy-analytics/${id}`}
                    className="italic hover:text-secondary underline cursor-pointer text-[#7e6eda] font-light text-[15px] p-1"
                  >
                    View advanced chart
                  </Link>
                </div>
                <div className="">
                  <Select
                    defaultValue={"today"}
                    placeholder="Select a date."
                    onChange={(e) => {
                      if (e == "today") {
                        setstartdateenergy(
                          moment(Date.now())
                            .startOf("today")
                            .format("YYYY-MM-DD HH:mm:ss")
                        );
                      } else if (e == "week") {
                        setstartdateenergy(
                          moment(Date.now())
                            .startOf("week")
                            .format("YYYY-MM-DD HH:mm:ss")
                        );
                      } else if (e == "month") {
                        setstartdateenergy(
                          moment(Date.now())
                            .startOf("month")
                            .format("YYYY-MM-DD HH:mm:ss")
                        );
                      } else if (e == "year") {
                        setstartdateenergy(
                          moment(Date.now())
                            .subtract("1", "year")
                            .format("YYYY-MM-DD HH:mm:ss")
                        );
                      }
                      setenddateenergy(
                        moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
                      );
                      setupdated((prev) => !prev);
                    }}
                    options={dateOptions}
                    style={{
                      width: 150,
                    }}
                  />
                </div>
              </div>
              <div className="h-[400px]">
                <MBarCharts
                  data={energydata?.data == 0 ? [] : energydata?.data}
                />
              </div>
            </div>
          </div>
        )} */}

        {/* plans and commands table  */}
        <div className="bg-white rounded-md drop-shadow-md p-2 mt-2">
          <div className="flex items-center justify-between">
            <div className="flex p-1 w-[fit-content] bg-gray-200 rounded-md items-center">
              <div
                onClick={() => settablestate(1)}
                className={
                  tablestate == 1
                    ? `bg-white rounded-md text-[14px] px-2 py-1 text-center cursor-pointer`
                    : `text-center cursor-pointer px-2 py-1 text-[14px]`
                }
              >
                Plans on meter
              </div>
              <div
                onClick={() => settablestate(2)}
                className={
                  tablestate == 2
                    ? `bg-white rounded-md px-2 py-1 text-[14px] text-center cursor-pointer`
                    : `text-center cursor-pointer text-[14px] px-2 py-1 `
                }
              >
                Commands on meter
              </div>
            </div>
            {ischannelowner && (
              <div
                onClick={() => {
                  navigate(`/channeladdplan/${id}`);
                }}
                className="w-[fit-content] py-1 px-3 mr-3 bg-secondary border-[1px] rounded-sm border-white flex items-center justify-center cursor-pointer"
              >
                <div className="font-normal text-[15px] text-white">
                  Add payment plan
                </div>
              </div>
            )}
          </div>

          {/* channel paymentplan */}
          {tablestate === 1 ? (
            <div className="h-[500px]">
              <ChannelPaymentPlanTable
                data={plans}
                refreshBtn={setrecallplans}
                plan={plandetails}
                open={planmodal}
                setplandetails={setplandetails}
                setopen={setplanmodal}
              />
            </div>
          ) : (
            // {tablestate === 2 && (
            <div className="mt-4">
              <div className="flex items-center justify-between">
                <div className="font-semibold text-gray-800">
                  {tabstate === 1
                    ? "All commands issued by users having access to meter"
                    : "Commands you issued on this meter"}
                </div>
                <div className="w-[200px]">
                  <Select
                    style={{
                      width: "100%",
                      height: 50,
                    }}
                    onChange={(e) => {
                      if (e == "1") {
                        settabstate(1);
                      } else {
                        settabstate(2);
                      }
                    }}
                    placeholder="Filter commands"
                    defaultValue={"2"}
                    options={[
                      {
                        label: "All commands",
                        value: "1",
                      },
                      { label: "Your commands", value: "2" },
                    ]}
                  />
                </div>
              </div>
              {tabstate === 1 ? (
                <div className="">
                  <AllCommandsOnChannel
                    data={usercommands}
                    loading={loading.commands}
                  />
                </div>
              ) : (
                <div className="">
                  <CommandsTable
                    data={allcommands}
                    loading={loading.commands}
                  />
                </div>
              )}
            </div>
            // )}
          )}

          {/* medium to large */}
          <div className="h-auto flex md:hidden items-start justify-center p-1">
            <div className=" rounded-md w-full h-full px-2">
              {tabstate == 1 &&
                (loading.commands ? (
                  <>
                    <div className="hidden md:block lg:block">
                      <TableSkimmer entries={12} />
                    </div>
                    <div className="block md:hidden">
                      {Array.from(Array(6)).map((_, i) => (
                        <div key={i} className="rounded-3 overflow-hidden my-2">
                          <Skimmer heigth={70} />
                        </div>
                      ))}
                    </div>
                  </>
                ) : !usercommands ? (
                  <div className="flex flex-col h-[400px] items-center justify-center">
                    <div className="w-[100px] h-[100px]">
                      <img
                        src={NocommandsImg}
                        alt=""
                        className="w-full h-full"
                      />
                    </div>
                    <div className="font-Kanit font-normal text-center ">
                      No commands has been issued on your device
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="hidden md:flex">
                      <CommandsTable data={usercommands} tabstate={tabstate} />
                    </div>
                    <div className="block md:hidden">
                      {usercommands.slice(0, 5).map((i, index) => (
                        <CommandCard
                          key={index}
                          data={i}
                          tabstate={tabstate}
                          username={username}
                        />
                      ))}
                    </div>
                  </>
                ))}
              {tabstate == 2 &&
                (loading.commands ? (
                  <>
                    <div className="hidden md:flex">
                      <TableSkimmer entries={12} />
                    </div>
                    <div className="block md:hidden">
                      {Array.from(Array(6)).map((_, i) => (
                        <div key={i} className="rounded-3 overflow-hidden my-2">
                          <Skimmer heigth={70} />
                        </div>
                      ))}
                    </div>
                  </>
                ) : allcommands.length == 0 ? (
                  <div className="flex flex-col  h-[400px] items-center justify-center">
                    <div className="w-[100px] h-[100px]">
                      <img
                        src={NocommandsImg}
                        alt=""
                        className="w-full h-full"
                      />
                    </div>
                    <div className="font-Kanit font-normal text-center ">
                      No commands has been issued on your device
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="hidden md:flex">
                      <CommandsTable data={allcommands} tabstate={tabstate} />
                    </div>
                    <div className="block md:hidden">
                      {allcommands.slice(0, 5).map((i, index) => (
                        <CommandCard
                          key={index}
                          data={i}
                          tabstate={tabstate}
                          username={username}
                        />
                      ))}
                    </div>
                  </>
                ))}
              {/* {
              <CommandsTable
                data={tabstate == 1 ? usercommands : allcommands}
                tabstate={tabstate}
              />
            } */}
            </div>
          </div>
        </div>

        <div className="pb-[200px]"></div>

        {/* modals */}
        {/* share Channel */}
        {sharemodal && (
          <ShareChannel open={sharemodal} closeBtn={setsharemodal} />
        )}
        {commandsModal && (
          <CommandsTab open={commandsModal} setopen={setcommandsModal} />
        )}
        {/* plan */}
        {planmodal && (
          <BuyPlan plan={plandetails} open={planmodal} setopen={setplanmodal} />
        )}
        {/* consumption */}
        {consumptionmodal && (
          <ConsumptionModal
            setopen={setconsumptionmodal}
            open={consumptionmodal}
          />
        )}
        {billingmodal && (
          <BillingModal setopen={setbillingmodal} open={billingmodal} />
        )}
        {/* {openshedule && ( */}
        <ModalCover open={openshedule} setopen={setopenshedule}>
          <div className="h-auto w-full py-3 px-2">
            <div className="flex items-center justify-between w-full">
              <div className="text-gray-800 font-semibold">
                {/* Schedule powerstate */}
                Control
              </div>
              <div
                className="cursor-pointer"
                onClick={() => setopenshedule(false)}
              >
                <VscClose size={21} />
              </div>
            </div>
            <div
              className="flex items-center justify-center cursor-pointer py-1"
              onClick={() => {
                setselectedpowerstate("con");
                setopenshedule(false);
                setopensheduledrawer(true);
              }}
            >
              <div className="w-[45px] h-[45px] flex items-center justify-center">
                <img src={ScheduleImg} className="w-full h-full" />
              </div>
              <div className="ml-3 py-2">
                <div className="font-semibold text-gray-800">
                  Schedule connection
                </div>
                <div className="font-light text-gray-700 w-full">
                  Use this option to select date when your channel will turn on.
                </div>
              </div>
            </div>
            <hr className="border-[0.3px] border-gray-300" />
            <div
              className="flex items-center justify-center cursor-pointer py-1"
              onClick={() => {
                setselectedpowerstate("dis");
                setopenshedule(false);
                setopensheduledrawer(true);
              }}
            >
              <div className="w-[45px] h-[45px] flex items-center justify-center">
                <img src={ScheduleImg} className="w-full h-full" />
              </div>
              <div className="ml-3 py-2">
                <div className="font-semibold text-gray-800">
                  Schedule disconnection
                </div>
                <div className="font-light text-gray-700 w-full">
                  Use this option to select date when your channel should turn
                  off.
                </div>
              </div>
            </div>
            <hr className="border-[0.3px] border-gray-300" />
            <div
              className="flex items-center justify-center cursor-pointer py-1"
              onClick={() => {
                setselectedpowerstate("lop");
                setopenshedule(false);
                setloopmodal(true);
              }}
            >
              <div className="w-[45px] h-[45px] flex items-center justify-center">
                <img src={ScheduleImg} className="w-full h-full" />
              </div>
              <div className="ml-3 py-2">
                <div className="font-semibold text-gray-800">
                  Loop connection
                </div>
                <div className="font-light text-gray-700 w-full">
                  Use this option to create loop connection on your device.
                </div>
              </div>
            </div>
          </div>
        </ModalCover>

        {loopmodal && <LoopTimer setopen={setloopmodal} open={loopmodal} />}

        {endmodal && <END setopen={setendmodal} open={endmodal} />}

        {/* setplanstatus */}
        {planstatus && (
          <PaymentPlanStatus
            open={planstatus}
            data={allplans}
            setopen={setplanstatus}
          />
        )}
        {/* Drawer */}
        <CON
          selectedpowerstate={selectedpowerstate}
          setopen={setopensheduledrawer}
          open={opensheduledrawer}
        />
      </div>
    </>
  );
};

export default ChannelDetails;
