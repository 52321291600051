import { useEffect, useState } from "react";
import Layout from "../../../../components/Layout";
import UserMeterChart from "../../../../components/Meter/Charts/UserMeterChart";
import { toast } from "react-hot-toast";
import { channelController } from "../../../../controllers/channelController";
import axios from "../../../../utils/axios";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Rule from "../../../../components/Rule/Index";
import ChannelImg from "../../../../assets/png/channelimg.png";
import moment from "moment";
import { getToken } from "../../../../utils/token";
import MeterInstructions from "../../../../components/Meter/Cards/MeterInstruction";
import { Button } from "../../../../components/Button";
import { FaUserFriends } from "react-icons/fa";
// import ChannelImg from "../../assets/png/channelimg.png";
const MeterDetails = () => {
  const [loadingmeter, setloadingmeter] = useState("");
  const [loadinguser, setloadinguser] = useState("");
  const [meterData, setmeterData] = useState([]);
  const [userData, setuserData] = useState([]);
  const { id } = useParams();
  const [metercount, setmetercount] = useState(0);

  // channels in device
  useEffect(() => {
    (async () => {
      try {
        setloadingmeter(true);
        let res = await axios.post("/submeter/getchannelsinadevice", {
          deviceid: id,
        });
        let result = channelController(res);
        if (result?.type !== "success") {
          toast[result.type](result?.message);
          setloadingmeter(false);
          return;
        }
        setmeterData(result.message?.body.slice(0, 4));
        setmetercount(result.message.meta?.count);
        setloadingmeter(false);
      } catch (err) {
        toast.error(err.message);
        setloadingmeter(false);
      }
    })();
    return () => {};
  }, []);
  //   shared with
  useEffect(() => {
    (async () => {
      try {
        let username = await getToken("spiral_token");
        setloadinguser(true);
        let res = await axios.post("/submeter/getusersdeviceissharedwith", {
          deviceid: id,
          username,
        });
        let result = channelController(res);
        if (result?.type !== "success") {
          toast[result.type](result?.message);
          setloadinguser(false);
          return;
        }
        setuserData(result.message?.body);
        setloadinguser(false);
      } catch (err) {
        toast.error(err.message);
        setloadinguser(false);
      }
    })();
    return () => {};
  }, []);

  let [searchParams, setSearchParams] = useSearchParams();
  const [startDatePq, setStartDatePq] = useState(
    moment(Date.now()).subtract(1, "day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDatePq, setEndDatePq] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );

  const dateFilter = [
    {
      id: 1,
      title: "Today",
      tag: "today",
    },
    {
      id: 2,
      title: "This week",
      tag: "week",
    },
    {
      id: 3,
      title: "This month",
      tag: "month",
    },
    {
      id: 4,
      title: "This year",
      tag: "year",
    },
  ];

  const [PqData, setPqData] = useState([]);
  const [refreshPf, setRefreshPf] = useState(false);
  const [activeTab, setactiveTab] = useState(1);
  // power quality
  useEffect(() => {
    (async () => {
      try {
        let user = await getToken("spiral_username");
        let res = await axios.post("/powerquality/analytics", {
          dcuids: JSON.stringify([id]),
          endactiveenergyln1: 0,
          endapparentpowerln1: 0,
          endcurrentln1: 0,
          enddate: endDatePq,
          endfreqln1: 0,
          endpfln1: 0,
          endrealpowerln1: 0,
          endvoltageln1: 0,
          meterids: "[]",
          startactiveenergyln1: 0,
          startapparentpowerln1: 0,
          startcurrentln1: 0,
          startdate: startDatePq,
          startfreqln1: 0,
          startpfln1: 0,
          startrealpowerln1: 0,
          startvoltageln1: 0,
        });
        let result = channelController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);
          return;
        }
        if (result.message == "customerror") {
          toast.error(result.message);
          console.log(`showing customer - ${result.message}`);
          return;
        }
        // console.log(result.message, "line 732");
        setPqData((prev) => result.message.body);
        // console.log(result.message, "-result");
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, [refreshPf]);

  const handleFilter = (id) => {
    switch (activeTab) {
      case 1:
        setStartDatePq(
          moment(Date.now).startOf("day").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      case 2:
        setStartDatePq(
          moment(Date.now).startOf("week").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      case 3:
        setStartDatePq(
          moment(Date.now).startOf("month").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      case 3:
        setStartDatePq(
          moment(Date.now).startOf("year").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      default:
        setStartDatePq(
          moment(Date.now).startOf("month").format("YYYY-MM-DD HH:mm:ss")
        );

        setRefreshPf((prev) => !prev);
    }
  };
  return (
    <>
      <div className="mt-6 w-full ">
        {/*  */}
        <div className="grid grid-cols-3 gap-6">
          <div className="h-[400px] bg-white shadow-md flex flex-col items-start justify-between p-2">
            <div className="w-full ">
              <div className="border-[1px] p-2 rounded-md w-full">
                <div className="font-semibold">Meter Name</div>
                <h1 className="text-3xl font-semibold">
                  {" "}
                  {!searchParams.get("name") ? "" : searchParams.get("name")}
                </h1>
              </div>
            </div>

            <div className="w-full mb-4">
              <div className="font-semibold">Meter location</div>
              <div className="h-[230px] mt-2 w-full rounded-md overflow-hidden">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.4327255436037!2d3.3118841137278237!3d6.466738225617246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b893bfa8341b5%3A0xb127f0657b3fd480!2sApple%20Estate%20Rd%2C%20Amuwo%20Odofin%20Estate%20102102%2C%20Ikeja!5e0!3m2!1sen!2sng!4v1647334921853!5m2!1sen!2sng"
                  width={"100%"}
                  height={"100%"}
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </div>
              <div className="mt-3 hidden">
                <Button className="mt-2" text={"Update location"} />
              </div>
            </div>
          </div>
          <div className="h-[400px] bg-white shadow-md flex flex-col items-start justify-start p-2">
            <div className="">
              <div className="font-semibold">Count</div>
            </div>
            <div className="border-[1px] p-2 rounded-md w-full">
              <p className="font-semibold mb-0">Devices</p>
              <div className="font-light text-[13px]">
                List of devices on meter
              </div>
              <h1 className="text-3xl font-semibold">
                {metercount ? metercount : 0}
              </h1>
            </div>
            <div className="border-[1px] p-2 mt-4 rounded-md w-full">
              <p className="font-semibold mb-0">Users on meter.</p>
              <div className="font-light text-[13px]">
                List of user on meter
              </div>
              <h1 className="text-3xl font-semibold">0</h1>
            </div>
          </div>

          <div className="h-[400px] p-2 bg-white shadow-md">
            <div className="">
              <div className="font-semibold">Meter settings</div>
              <div className="text-[12px]">click on any of the options</div>
              <MeterInstructions />
            </div>
          </div>
        </div>
        {/* details */}
        <div className="w-full mt-6 gap-6 grid grid-cols-2">
          <div className="h-[400px] p-2 bg-white shadow-md">
            <div className="">
              <div className="font-semibold">Users</div>
              <div className="flex flex-col h-[300px] items-center justify-center">
                <div className="">
                  <FaUserFriends color="#6b48ff" size={32} />
                </div>
                <div className="">
                  You haven't shared this meter with anyone yet.
                </div>
              </div>
            </div>
          </div>
          <div className="h-[400px] p-2 bg-white shadow-md">
            <div className="">
              <div className="font-semibold">Devices</div>
            </div>
            <div className="">
              {meterData.map((i) => (
                <Link
                  to={`/channel/${i?.channelid}`}
                  className="border-b-1 block p-2 my-4 hover:bg-[#f5f5f5de] border-b-[1px] cursor-pointer even:bg-[#f5f5f5de]"
                  key={i.channelid}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex">
                      <div className="w-[40px] h-[40px]">
                        <img src={ChannelImg} className="w-100 h-100" />
                      </div>
                      <div className="">
                        <div className="text-black font-semibold">
                          {i?.devicealias}
                        </div>
                        <div className="text-black">
                          Access: {i?.accesscontrol}
                        </div>
                      </div>
                    </div>
                    <div className="text-[12px] text-black">
                      {moment(i?.dateadded).format("lll")}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="w-full p-2 bg-white mt-6 shadow-md">
          <div className="flex items-center justify-between mb-6">
            <div className="">
              <div className="font-semibold">Consumption analytics</div>
              <div className=" mt-2">View consumption on your meter</div>
            </div>
            <div className="flex  bg-[#f6f6f6] rounded-md py-2 px-1">
              {dateFilter.map((i) => (
                <div
                  onClick={() => {
                    setactiveTab(i.id);
                    handleFilter();
                  }}
                  className={`rounded-md cursor-pointer ${
                    activeTab == i.id
                      ? "bg-white shadow-md inset-3 text-black"
                      : " bg-[#f6f6f6] text-black"
                  }  h-[35px] px-2 mx-2 first:ml-0 last:mr-0 flex items-center justify-center w-[fit-content]  font-light`}
                  key={i.id}
                >
                  <p className={`font-normal mb-0 text-[12px]`}>{i.title}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="h-[500px] ">
            <UserMeterChart data={PqData} />
          </div>
        </div>
        <div className="h-[200px]"></div>
      </div>
    </>
  );
};

export default MeterDetails;
