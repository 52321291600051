import { useParams } from "react-router-dom";
import ChannelUsersTable from "../../../components/Channels/Tables/ChannelUsersTable";
import Layout from "../../../components/Layout";
import { getToken } from "../../../utils/token";
import { channelController } from "../../../controllers/channelController";
import { useEffect, useState } from "react";
import axios from "../../../utils/axios";
import TableSkimmer from "../../../components/TableSkimmer";
import BackBtn from "../../../components/BackBtn";
const ChannelUsers = () => {
  const { id } = useParams();
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const username = await getToken("spiral_username");
        let res = await axios.post("/submeter/getuserschannelissharedwith", {
          username,
          channelid: id,
        });
        let result = channelController(res);
        const { body } = result.message;
        setdata(body);
        setloading(false);
        setloading(false);
      } catch (err) {
        setloading(false);
      }
    })();
  }, []);
  return (
    <>
      <div className="mt-6 bg-white h-[600px] shadow-md p-4">
        <BackBtn text="back to device" />
        <div className="px-3 py-2">
          <div className="font-semibold font-Kanit text-[17px]">
            Users on your device page
          </div>
          <div className="text-gray-400 text-start font-normal">
            List of all users on the your device.
          </div>
        </div>
        {loading ? (
          <TableSkimmer entries={10} col={7} />
        ) : (
          <ChannelUsersTable data={data} />
        )}
      </div>
    </>
  );
};

export default ChannelUsers;
