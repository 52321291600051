import { Drawer, Tabs, Input, Radio, Form, Button } from "antd";
import { BsGridFill } from "react-icons/bs";
import { useState, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import { toast } from "react-hot-toast";
import { useLocation, useParams } from "react-router-dom";
import { getToken } from "../../../../utils/token";
import { commandController } from "../../../../controllers/CommandController";
import axios from "../../../../utils/axios";
import { channelController } from "../../../../controllers/channelController";

const { TabPane } = Tabs;

const ChannelGroups = ({
  title,
  open,
  setopen,
  caption,
  selectedchannel,
  refreshBtn,
  setselectedchannel,
}) => {
  const [form] = Form.useForm();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [saving, setsaving] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const controller = new AbortController();
  const [limit, setlimit] = useState(100);
  const [offset, setoffset] = useState(0);
  const [name, setname] = useState("");
  const [data, setdata] = useState([]);
  const [inputname, setinputname] = useState("");
  const [selectedgroup, setselectedgroup] = useState("");

  useEffect(() => {
    fetchData();
    return () => {
      controller.abort();
    };
  }, []);

  const fetchData = async () => {
    try {
      let res = await axios.post("/submeter/getallchannels", {
        limit,
        offset,
        groupprefix:
          location.pathname == "/dashboard"
            ? ""
            : location.pathname.substring(15),
      });
      const result = channelController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        return;
      }
      const item = result.message.body.filter((item) => item?.type == "group");
      setdata(item);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleNew = async () => {
    try {
      if (!name) {
        toast.error("Please enter a group name");
        return;
      }

      let username = await getToken("spiral_username");
      let pathName = location.pathname.includes("/channel/group/")
        ? `\${location.pathname.substring(15).replaceAll("_", " ").trim()}/\${name.trim()}`
        : location.pathname.includes("/dashboard")
        ? `\${name.trim()}`
        : name.trim();

      setsaving(true);
      let res = await axios.post("/submeter/editchannelgroup", {
        channelid:
          selectedchannel.type == "group"
            ? selectedchannel.alias.trim()
            : selectedchannel.channelid.trim(),
        username: username,
        newgroup: pathName,
      });

      const result = channelController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setsaving(false);
        return;
      }

      toast.success(`Device added successfully.`);
      setsaving(false);
      refreshBtn((prev) => !prev);
      setopen(false);
    } catch (err) {
      toast.error(err.message);
      setsaving(false);
    }
  };

  const handleExisting = async () => {
    try {
      console.log(selectedgroup, "selected");
      // let username = await getToken("spiral_username");
      // let pathName = location.pathname.includes("/channel/group/")
      //   ? `\${location.pathname.substring(15).replaceAll("_", " ")}/\${selectedgroup}`
      //   : location.pathname.includes("/dashboard")
      //   ? `\${selectedgroup}`
      //   : `\${selectedgroup}`;
      // setsaving(true);
      // let res = await axios.post("/submeter/editchannelgroup", {
      //   channelid:
      //     selectedchannel.type == "group"
      //       ? selectedchannel.channelid
      //       : selectedchannel.channelid,
      //   username: username,
      //   newgroup: pathName,
      // });
      // const result = channelController(res);
      // if (result.type !== "success") {
      //   toast[result.type](result.message);
      //   setsaving(false);
      //   return;
      // }
      // toast.success(`Device added successfully.`);
      // setsaving(false);
      // refreshBtn((prev) => !prev);
      // setopen(false);
    } catch (err) {
      toast.error(err.message);
      setsaving(false);
    }
  };

  return (
    <Drawer
      title={title}
      placement="right"
      onClose={() => setopen(false)}
      open={open}
      width={400}
    >
      <div className="h-full flex flex-col">
        <p className="text-gray-600 text-md m-0 font-semibold">
          Devices and groups
        </p>
        <Tabs activeKey={activeTab} onChange={setActiveTab}>
          <TabPane tab="New group" key="1">
            <Form size="large" form={form} layout="vertical">
              <Form.Item
                label="Enter new group name"
                name="newGroupName"
                rules={[
                  { required: true, message: "Please enter a group name" },
                ]}
              >
                <Input
                  prefix={<BsGridFill className="text-primary" />}
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                  placeholder="Enter your preferred name"
                />
              </Form.Item>
            </Form>
          </TabPane>

          <TabPane tab="Existing group" key="2">
            <Input
              size="large"
              prefix={<FiSearch className="text-primary" />}
              value={inputname}
              onChange={(e) => setinputname(e.target.value)}
              placeholder="Search for a group"
              className="mb-4"
            />

            <div className="flex-1 overflow-y-auto">
              <Radio.Group
                className="!w-full"
                value={selectedgroup}
                onChange={(e) => setselectedgroup(e.target.value)}
              >
                {data
                  .filter((i) =>
                    i.alias
                      .toLowerCase()
                      .trim()
                      .includes(inputname.toLowerCase())
                  )
                  .map((item, index) => (
                    <Radio.Button
                      key={index}
                      value={item.alias}
                      className="!w-full !h-auto mb-2 p-3"
                    >
                      <div className="flex flex-col !w-full justify-between items-start">
                        <div className="font-semibold">{item.alias}</div>
                        <div className="">Device count: {item.devicecount}</div>
                      </div>
                    </Radio.Button>
                  ))}
              </Radio.Group>
            </div>
          </TabPane>
        </Tabs>

        <div className="mt-auto pt-4">
          <Button
            type="primary"
            size="large"
            loading={saving}
            className="!w-full  mt-4 !bg-primary !outline-none !border-none"
            onClick={activeTab === "1" ? handleNew : handleExisting}
          >
            Add to group
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default ChannelGroups;
