import axios from "../../../utils/axios";
import { useEffect, useState, useContext } from "react";
import { toast } from "react-hot-toast";
import PaymentPlanTableMain from "../../../components/PaymentPlan/PaymentPlanTableMain";
import Rule from "../../../components/Rule/Index";
import { channelController } from "../../../controllers/channelController";
import { billingController } from "../../../controllers/BillingController";
import { errorBlock } from "../../../controllers/errorBlock";
import TableSkimmer from "../../../components/TableSkimmer";
import ViewPaymentPlan from "../../../components/PaymentPlan/Modals/ViewPaymentPlan";
import AddPlan from "../../../components/PaymentPlan/Drawer/AddPlan";
import PlanType from "../../../components/PaymentPlan/Modals/PlanType";
import Skimmer from "../../../components/Loader/Skimmer";
import MainPaymentPlanCard from "../../../components/PaymentPlan/Cards/MainPaymentPlanCard";
import { Button } from "antd";

const PaymentPlan = () => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(true);
  const [modal, setmodal] = useState(false);
  const [details, setdetails] = useState(null);
  const [refresh, setrefresh] = useState(false);
  const [addplanmodal, setaddplanmodal] = useState(false);
  const [selectedplantype, setselectedplantype] = useState("");
  const [opendrawer, setopendrawer] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/submeter/paymentplan/getallpaymentplans", {
          planalias: "",
        });
        setdata(res.data.body);
        setloading(false);
      } catch (err) {
        if (err?.response) {
          toast.error(err.response?.data?.response);
          setloading(false);
          return;
        }
        toast.error(err.message);
        setloading(false);
      }
    })();
    return () => {};
  }, [refresh]);

  return (
    <>
      <AddPlan
        open={opendrawer}
        setopen={setopendrawer}
        refreshBtn={setrefresh}
        selectedplantype={selectedplantype}
      />
      {modal && (
        <ViewPaymentPlan
          details={details}
          setopen={setmodal}
          open={modal}
          refreshBtn={setrefresh}
        />
      )}
      {addplanmodal && (
        <PlanType
          open={addplanmodal}
          setopen={setaddplanmodal}
          setselectedplantype={setselectedplantype}
          opendrawer={setopendrawer}
        />
      )}
      <>
        <div className="bg-white rounded-sm h-[90vh]">
          <div className="flex items-center justify-between">
            <div className=" pt-3 px-3">
              <div className="text-md font-semibold text-gray-800">
                Payment plans
              </div>
              <div className="text-gray-600">
                All the payment plans you have created
              </div>
            </div>
            {/* <div className="w-[150px]">
                <Button text={"Add plan"} />
              </div> */}
            <Button
              size=""
              type="primary"
              htmlType="submit"
              // loading={loading}
              onClick={() => setaddplanmodal(true)}
              className="!w-fit  mt-4 !bg-primary !outline-none !border-none"
            >
              {" "}
              Add Plan
            </Button>
          </div>

          <Rule />
          <div className=" hidden md:block">
            {loading ? (
              <TableSkimmer entries={10} col={7} />
            ) : (
              <PaymentPlanTableMain
                data={data}
                setdetails={setdetails}
                setmodal={setmodal}
              />
            )}
          </div>
          <div className="block md:hidden px-3">
            {loading
              ? [1, 2, 3, 4, 5].map((_, i) => (
                  <div className="rounded-md overflow-hidden" key={i}>
                    <Skimmer heigth={100} />
                  </div>
                ))
              : data.map((i, index) => (
                  <MainPaymentPlanCard
                    data={i}
                    key={index}
                    plandetail={details}
                    setplandetails={setdetails}
                    planmodal={modal}
                    setplanmodal={setmodal}
                  />
                ))}
          </div>
        </div>
        <div className="h-[200px]"></div>
      </>
    </>
  );
};

export default PaymentPlan;
