import React, { useState, useEffect } from "react";
import CardTitle from "../../../components/CardTitle";
import Layout from "../../../components/Layout";
import TOUCard from "../../../components/MeterClass/TOUCard";
import TOUModal from "../../../components/MeterClass/TOUModal";
import TopNav from "../../../components/TopNav";
import { themeColor as color } from "../../../constant/color";
import "./styles.css";
import axios from "../../../utils/axios";
import toast from "react-hot-toast";
import { Button } from "../../../components/Button";
import { meterController } from "../../../controllers/MeterController";
import TopNavTitle from "../../../components/TopNav/TopNavTitle";
import BackBtn from "../../../components/BackBtn";
import { Select } from "antd";
import moment from "moment";
import { getToken } from "../../../utils/token";
import { useNavigate } from "react-router-dom";

const AddMeterClass = () => {
  const [modal, setModal] = useState(false);
  const [loading, setloading] = useState(false);
  const [tou, setTOU] = useState([]);
  const [classdescription, setclassdescription] = useState("");
  const [meter, setmeter] = useState("");
  const [amountperkwh, setAmountperkwh] = useState("");
  const [customerclass, setCustomerClass] = useState("");
  const [startDate, setStartDate] = useState(
    moment(Date.now()).subtract("1", "year").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const controller = new AbortController();
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/meter/getallmeters", {
          limit: 10000,
          offset: 0,
          startdate: startDate,
          enddate: endDate,
        });
        let result = meterController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);
          return;
        }
        setData(
          result.message.body.map((i) => {
            return {
              label: `${i.number} (${i.phases}) phase`,
              value: `${i.number}`,
            };
          })
        );
      } catch (err) {
        toast.error(err.message);
      }
    })();

    return () => {
      controller.abort();
    };
  }, []);

  const submitMeterClass = async () => {
    try {
      if (!classdescription) return toast.error("Please enter a description");
      if (!meter) return toast.error("Please select a meter.");
      if (!amountperkwh) return toast.error("Please enter a bliing amount");
      if (!customerclass) return toast.error("Please enter a Meter class name");
      if (!tou.length) {
        return toast.error("Please select atleast one time of use");
      }
      let result = tou.map((item) => {
        return (
          "#" +
          item.st.replaceAll(":", "") +
          item.et.replaceAll(":", "") +
          item.bp
        );
      });
      setloading(true);
      // console.log(
      //   classdescription,
      //   result.join(""),
      //   amountperkwh,
      //   customerclass
      // );
      let username = await getToken("ampere_username");
      let res = await axios.post("/commands/createmeterclass", {
        // classdescription: classdescription,
        tou: result.join(""),
        // username,
        meterclass: customerclass,
        dcuid: meter,
        transactiontopic: `mqtt_${username}`,
      });

      let response = meterController(res);
      if (response.type !== "success") {
        toast[response.type](response.message);
        setloading(false);
        return;
      }

      setloading(false);
      toast.error("Meterclass created successfully.");
      // navigate("/")
    } catch (err) {
      toast.error(err.message);
      setloading(false);
    }
  };
  return (
    <>
      {modal && <TOUModal closeModal={setModal} stf={setTOU} itou={tou} />}
      <div className="px-3 pt-3">
        <div className="container-fluid ">
          <div className="row my-2">
            <BackBtn text={"Go back"} />
          </div>
          <div
            className="row p-2 bg-white rounded-1 shadow-sm"
            style={{
              marginBottom: "300px",
            }}
          >
            <div className="">
              <CardTitle
                title={"Create meter class"}
                caption="Fill in the fields below to create new meterclass"
              />
            </div>
            {/* Meter class Name */}
            <div className="row py-3 meter-class__entry">
              <div className="col-sm-12 col-md-10 col-lg-9 col-xl-9 col-xxl-9">
                <div className="row d-flex align-items-center justify-content-between">
                  <div className="col-sm-12 col-md-7 col-lg-5 col-xl-5 col-xxl-5 my-2">
                    <div
                      className="meter-class__title"
                      onClick={() => console.log(tou)}
                    >
                      Select a meter
                    </div>
                    <div className="meter-class__caption">
                      Start by searching for a meter.
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 my-2">
                    <div className="w-100 meter-class__input p-2 rounded-1">
                      {/* <input
                        type={"text"}
                       
                        placeholder="Enter meterclass name"
                        value={customerclass}
                        onChange={(e) => setCustomerClass(e.target.value)}
                      /> */}
                      <Select
                        defaultValue="lucy"
                        style={{
                          width: "100%",
                        }}
                        showSearch
                        showArrow
                        bordered={false}
                        size="middle"
                        onChange={(e) => {
                          setmeter(e);
                        }}
                        options={data}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Meter class Name */}
            <div className="row py-3 meter-class__entry">
              <div className="col-sm-12 col-md-10 col-lg-9 col-xl-9 col-xxl-9">
                <div className="row d-flex align-items-center justify-content-between">
                  <div className="col-sm-12 col-md-7 col-lg-5 col-xl-5 col-xxl-5 my-2">
                    <div
                      className="meter-class__title"
                      onClick={() => console.log(tou)}
                    >
                      Meterclass name
                    </div>
                    <div className="meter-class__caption">
                      Enter a name to describe the meter class you want to
                      create
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 my-2">
                    <div className="w-100">
                      <input
                        type={"text"}
                        className="meter-class__input p-2 rounded-1"
                        placeholder="Enter meterclass name"
                        value={customerclass}
                        onChange={(e) => setCustomerClass(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Description */}
            <div className="row py-3 meter-class__entry">
              <div className="col-sm-12 col-md-10 col-lg-9 col-xl-9 col-xxl-9">
                <div className="row d-flex align-items-center justify-content-between">
                  <div className="col-sm-12 col-md-7 col-lg-5 col-xl-5 col-xxl-5 my-2">
                    <div className="meter-class__title">
                      Meterclass description
                    </div>
                    <div className="meter-class__caption">
                      Enter a short note to describe your meterclass.
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 my-2">
                    <div className="w-100">
                      <textarea
                        type={"text"}
                        className="meter-class__input p-2 rounded-1"
                        style={{
                          height: "100px",
                          resize: "none",
                        }}
                        placeholder="Enter a brief description for your class."
                        value={classdescription}
                        onChange={(e) => setclassdescription(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Description */}
            <div className="row py-3 meter-class__entry">
              <div className="col-sm-12 col-md-10 col-lg-9 col-xl-9 col-xxl-9">
                <div className="row d-flex align-items-center justify-content-between">
                  <div className="col-sm-12 col-md-7 col-lg-5 col-xl-5 col-xxl-5 my-2">
                    <div className="meter-class__title">Billing amount</div>
                    <div className="meter-class__caption">
                      How much would you like to charge when this meter is
                      activated on a meter.
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 my-2">
                    <div className="w-100">
                      <input
                        type={"text"}
                        className="meter-class__input p-2 rounded-1"
                        placeholder="Enter billing amount"
                        value={amountperkwh}
                        onChange={(e) => setAmountperkwh(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Time of use */}
            <div className="row py-3 meter-class__entry d-flex align-items-start">
              <div className="col-sm-12 col-md-10 col-lg-9 col-xl-9 col-xxl-9">
                <div className="row d-flex align-items-start justify-content-between">
                  <div className="col-sm-12 col-md-7 col-lg-5 col-xl-5 col-xxl-5 my-2">
                    <div className="meter-class__title">Time of use</div>
                    <div className="meter-class__caption">
                      What time of the days should this Meterclass be active.
                      Note: The time picker is a 24 hours clock and also ensure
                      that the time you select don't overlap.
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 my-2">
                    <div
                      className="w-100 p-2 rounded"
                      style={{
                        height: "200px",
                        position: "relative",
                        overflowX: "hidden",
                        border: `1px solid ${color.baseColor}`,
                        overflowY: "scroll",
                        boxShadow: "inset 0 3px 5px #eeeeeede",
                        zIndex: 10,
                      }}
                    >
                      <div
                        className="px-3 py-1 text-center rounded-2 text-white tou-btn"
                        style={{
                          background: color.baseColor,
                          cursor: "pointer",
                        }}
                        onClick={() => setModal(true)}
                      >
                        Add TOU
                      </div>
                      {/* Tou card */}
                      {tou.map((item, i) => (
                        <TOUCard
                          key={i}
                          data={item}
                          setTOU={setTOU}
                          tou={tou}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Btn */}
            <div className="row py-3 meter-class__entry d-flex align-items-start justify-content-end">
              <div
                className="col-sm-6 col-md-4 col-lg-2 col-xl-2 xol-xxl-2 "
                onClick={submitMeterClass}
              >
                <Button
                  text={"Save"}
                  height={"39px"}
                  status={loading}
                  bg={color.baseColor}
                  color={color.whiteColor}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddMeterClass;
