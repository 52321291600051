import moment from "moment";
import { NotificationFormatter } from "../../../../helperFuncs";
import { FcCalendar } from "react-icons/fc";

const MainPaymentPlanCard = ({
  data,
  plandetails,
  setplandetails,
  planmodal,
  setplanmodal,
}) => {
  return (
    <div
      className="my-2 border-b-[1px] py-2 cursor-pointer"
      onClick={() => {
        setplandetails(data);
        setplanmodal(true);
      }}
    >
      <div className="flex items-center justify-between ">
        <h1 className="font-semibold">{data.planalias}</h1>
        <div className="flex text-right font-light">
          {moment(data.dateassigned).format("YYYY-MM-DD")}
        </div>
      </div>
      <div className="flex items-end  justify-between w-full">
        <p className="m-0">{data?.description.substring(0, 30)}...</p>
        <div className="text-secondary font-semibold">
          ₦{data?.amount.toLocaleString("en-US", { minimumFractionDigits: 2 })}{" "}
        </div>
      </div>
    </div>
  );
};

export default MainPaymentPlanCard;
