import { Select } from "antd";
import axios from "../../utils/axios";
import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import AllCustomerTable from "../../components/SalesAgent/Table/AllCustomerTable";
import TableSkimmer from "../../components/TableSkimmer";
import { channelController } from "../../controllers/channelController";
import { customerFilterData } from "./data";
import Rule from "../../components/Rule/Index";
import { tab } from "@syncfusion/ej2-react-grids";
import ProjectSiteMeters from "../../components/ManageMeter/ProjectSiteMeters";
import CustomerMeterDetails from "../../components/SalesAgent/Modals/CustomerMeterDetails";
import { getToken } from "../../utils/token";

const ManagerMeter = () => {
  const [tabstate, settabstate] = useState(1);
  const [data, setData] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [columnInput, setColumnInput] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, setmodal] = useState(false);
  const [details, setdetails] = useState(null);
  const [sitename, setsitename] = useState("");

  const [search, setSearch] = useState("");
  const [dropDown, setDropDown] = useState([]);
  const [meter, setMeter] = useState([]);
  const [selectedZone, setSelectedZone] = useState("");
  const { Option } = Select;
  const controller = new AbortController();
  useEffect(() => {
    (async () => {
      try {
        // 7654
        // if (!selectedColumn && !columnInput) return null;
        setLoading(true);
        let res = await axios.post("/customer/getallmetercustomers", {
          column: selectedColumn,
          columnparameter: columnInput,
        });
        let result = channelController(res);
        if (result.type !== "success") {
          toast.error(result.message);
          setLoading(false);
          return;
        }
        setData(result.message.body);
        setLoading(false);
        // 3456
      } catch (err) {
        toast.error(err.message);
        setLoading(false);
        // 3456
      }
    })();

    return () => {
      controller.abort();
    };
  }, [refresh]);

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/sitemanagement/getallsitesdetails", {});
        let result = channelController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);
          return;
        }
        setDropDown(result.message.body.map((item) => item.sitename));
      } catch (err) {
        toast.error(err.message);
      }
    })();
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        let site = await getToken("spiral_site");
        setsitename(site);
        let res = await axios.post("/meter/getallmetersinzone", {
          zone: site,
        });
        let result = channelController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);
          return;
        }
        setMeter(result.message.body);
      } catch (err) {
        toast.error(err.message);
      }
    })();
    return () => {
      controller.abort();
    };
  }, [selectedZone]);
  return (
    <>
      {modal && (
        <CustomerMeterDetails
          setopen={setmodal}
          open={modal}
          details={details}
        />
      )}
      <div className="bg-white rounded-md drop-shadow-md px-4">
        <PageTitle
          title={"Meters on spiral"}
          caption={"For customers and project site"}
        />
        <div className="">
          <div className="flex items-center mt-6">
            <div
              className={
                tabstate == 1
                  ? "px-8 py-1 border-b-[2px] border-gray-800 mx-2 cursor-pointer"
                  : "px-8 py-1 border-b-[2px] border-gray-300 mx-2 cursor-pointer"
              }
              onClick={() => settabstate(1)}
            >
              Customer Meter
            </div>
            <div
              className={
                tabstate == 2
                  ? "px-8 py-1 border-b-[2px] border-gray-800 mx-2 cursor-pointer"
                  : "px-8 py-1 border-b-[2px] border-gray-300 mx-2 cursor-pointer"
              }
              onClick={() => settabstate(2)}
            >
              Project Site Meters
            </div>
          </div>
          {tabstate == 1 && (
            <div className="">
              <div className="flex items-center justify-between mb-4">
                <div className="">
                  <PageTitle
                    title={"All customers " + sitename}
                    caption="Description"
                  />
                </div>
                <div className="">
                  <div className="text-end font-semibold">
                    Advanced search filter.
                  </div>
                  <div className="flex items-center justify-between border-[1px] rounded-md overflow-hidden border-gray-600">
                    <div className="border">
                      <Select
                        style={{
                          height: "36px",
                          width: "130px",
                        }}
                        onChange={(e) => setSelectedColumn(e)}
                        options={customerFilterData}
                        bordered={false}
                        placeholder={"Select field."}
                      />
                    </div>
                    <div className="flex bg-white items-center w-100 h-full">
                      <input
                        required
                        value={columnInput}
                        onChange={(e) => setColumnInput(e.target.value)}
                        type={"text"}
                        placeholder={"Enter name."}
                        className="px-2 placeholder:text-sm placeholder:font-thin w-full h-[35px] outline-none border-none"
                      />
                    </div>
                    <div className="border bg-gray-800 rounded-md">
                      {loading ? (
                        <button
                          disabled={loading}
                          className="px-4 py-2 text-gray-50"
                        >
                          Search...
                        </button>
                      ) : (
                        <button
                          onClick={() => setRefresh((prev) => !prev)}
                          className="px-4 py-2 text-gray-50 cursor-pointer"
                        >
                          Search
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                {loading ? (
                  <TableSkimmer entries={10} col={7} />
                ) : (
                  <AllCustomerTable
                    data={data}
                    setdetails={setdetails}
                    setmodal={setmodal}
                  />
                )}
              </div>
            </div>
          )}

          {tabstate == 2 && (
            <ProjectSiteMeters
              dropDown={dropDown}
              data={meter}
              setSelectedZone={setSelectedZone}
              selectedZone={selectedZone}
              sitename={sitename}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ManagerMeter;
